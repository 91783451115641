import React from "react";
import TodoListIncoming from "../../Todos/TodosIncomingApp";
import SubMenu from "../../Submenu/SubmenuApp";
import './IncomingTodosPage.css';
import '../GeneralPageStyles.css';
import { ReactComponent as IncomingIcon } from "../../../src/icons/svg/incoming-icon.svg"


export default function IncomingTodosPage({setIcon}) {
    return (
        <div  className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="page-container">
                    <div className="page-info">
                        <IncomingIcon className="page-info__incoming-icon"/>
                        <h1 className="page-title page-title-incoming">Входящие задачи</h1>
                    </div>
                    <div>
                        <TodoListIncoming setIcon={setIcon}></TodoListIncoming>
                    </div>
                </div>
            </div>
    )
}

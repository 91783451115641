import request from "../Requests/requests";
// import { editTodoIncoming } from "../store/incomingTodosReducer";
import { getTodos } from "./getTodosToday";
import { getTimeAllTodos } from "./getTimeAllTodos";
import { getTodosIncoming } from "./getIncomingTodos";
import { getCalendarTodos } from "./CalendarService/getCalendarTodos";

export const editTodo = (payload) => {
    const dataLoad = {pageSize: payload.pageSize ? payload.pageSize : 10, pageIndex: payload.pageIndex ? payload.pageIndex : 1};
    return async dispatch => {
        await request.put('/Task/Update', {
                taskId: payload.id,
                userId: payload.userId,
                title: payload.title !== null ? payload.title.replace(/(<([^>]+)>)/gi, '') : payload.title,
                description: payload.description !== null ? payload.description : payload.description,
                priority: payload.priority,
                hours: payload.hours,
                minutes: payload.minutes,
                date: payload.date,
                time: payload.time,
                tagsId: payload.tags?.map((tag) => tag.tagId),
                isOpened: payload.isOpened,
            })
        // dispatch(editTodoIncoming(payload));
        setTimeout(() => {
            dispatch(getTodosIncoming(dataLoad));
            dispatch(getTodos());
            dispatch(getTimeAllTodos());
            dispatch(getCalendarTodos(payload?.updatePageCalendar ? payload.updatePageCalendar : null))
        }, 100);
        }
    }
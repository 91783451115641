import React, { useRef } from "react";
import * as signalR from "@microsoft/signalr";
import { activateTimer } from "../store/RunTimeTaskResults/RunTimeResults";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { useEffect } from "react";
import "./index.css";
import { editTodo } from "../asyncActions/editTodos";
import { timerPush } from "../asyncActions/TimerActions/timerPush";
import { autoClosePreviousTimer } from "./TaskStopWatch";
import { addRunTime } from "../asyncActions/RunTimeActions/addRunTime";

interface CountDownProps {
    task: {
        date: Date,
        description: string,
        hours: number,
        id: number,
        isCompleted: boolean,
        isDelete: boolean,
        isOpened: boolean,
        minutes: number,
        priority: number,
        runTime: number,
        tags: string[]
        time: string,
        timerIsActive: boolean
        timerStart: number
        timerTime: number,
        title: string,
        transferCount: number,
        userId: string
    },
    id: number,
    isOpened: boolean,
    updateIsOpened: Function,
    disabled: boolean
}

export default function TaskCountDown ({id, task, isOpened, updateIsOpened, disabled}: CountDownProps) {
    const dispatch = useDispatch();
    const url = (process.env.REACT_APP_API_BASEURL_HUB ?? 'https://api-demo.timetask.ru/') + 'TimerHub' ;
    const stopWatchesData = useSelector((state: any) => state.stopWatchesData.stopWatchesData);
    const actionArray = stopWatchesData.find((actionArr: { taskId: number }) => actionArr.taskId === id);
    const [countTimer, setCountTimer] = React.useState<number>(15);
    const [connection, setConnection] = React.useState<any>(null);
    const [activeTimer, setActiveTimer] = React.useState<any>();
    const countdownRef = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        setActiveTimer(stopWatchesData.find((timer: any) => timer.isTimerActive === true))
    }, [stopWatchesData, actionArray])
    
    useEffect(() => {
        if (isOpened === true) {
            return 
        } else if (disabled) {
            return
        } else {
            countdownRef.current = setInterval(() => {
                if (countTimer === 0) {
                    clearInterval(countdownRef.current as NodeJS.Timeout)
                    StartStopTimer(new Date().getTime())
                } else {
                    setCountTimer(countTimer - 1)
                }
            }, 1000)
        }
            return () => clearInterval(countdownRef.current as NodeJS.Timeout)
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, [countTimer, actionArray, connection, id, isOpened, updateIsOpened, dispatch])
        
        const sendTimer = (startTime: number) => {            
            connection.invoke("Send", localStorage.getItem('token'), id, startTime, true)
            .catch((err: any) => console.error(err));
        }
        
        useEffect(() => {
            const newConnection = new signalR.HubConnectionBuilder()
                .withUrl(url)
                .withAutomaticReconnect()
                .build()
            setConnection(newConnection)
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, []);
        
        useEffect(() => {
            if (connection) {
                connection.start()
                .then(() => {
                    connection.on("Receive", (userId: string, taskId: number, timerStart: number) => {
                        if (userId === localStorage.getItem('token')) {
                            dispatch(activateTimer({isTimerActive: true, taskId: taskId, startTime: timerStart}));
                            clearInterval(countdownRef.current as NodeJS.Timeout)
                        }
                    });
                })
                .catch((err: any) => console.log('Error while establishing connection: ', err));
            }
            /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, [connection])

        function StartStopTimer(start: any) {
            if(!task.isOpened) {
                dispatch<any>(editTodo({
                    ...task,
                    isOpened: true
                }))
            }
            autoClosePreviousTimer(activeTimer?.taskId, task.id).then((runTime: any) => {
                dispatch<any>(addRunTime({id: activeTimer?.taskId, seconds: Math.floor((start - activeTimer?.startTime) / 1000) + runTime}))
            })
            dispatch<any>(timerPush({taskId: task.id, timerIsActive: !actionArray?.isTimerActive, startTime: start}))
            sendTimer(start);
            updateIsOpened({isOpened: true});
        }
        return (
            <div style={disabled ? {display:"none"} : {display: "block"}}>
                <p className="countdown-timer">отсчет времени через: {countTimer} сек.</p>
            </div>
    )
}
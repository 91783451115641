import React from "react";
import './Validation.css';

interface ValidationProps {
    data: string[],
    keyItem: string,
}

export default function Validation({data, keyItem} : ValidationProps) {    
    function checkKey(error: any) {
        if (error.key === keyItem) {            
            return <p className="validation-alert">* {error.err}</p>
        } else {
            return
        }
    }

    return (
        data.length === 0 ? null :
            data.map((item) => {                
                return checkKey(item) 
        })
    )
}
import React from "react";
import './DonationBtn.css';
import {ReactComponent as DonationIcon} from './static/donation-icon.svg';

const DonationBtn = ({title, updateModalState, updateTitleModal, updateDescriptionModal, updateLogoModal, updateButtonsModal, dataToModal, redirectTo}) => {

    const handleOpenModal = () => {
        updateModalState(true)
        updateTitleModal(dataToModal.title)
        updateDescriptionModal(dataToModal.description)
        updateLogoModal(dataToModal.logo)
        updateButtonsModal(dataToModal.buttons)
    }

    // const handleRedirectTo = () => {

    // }

    return (
        <>
            <button className="donation-btn" onClick={() => handleOpenModal()} onTouchStart={() => handleOpenModal()}>
                {
                    redirectTo ? 
                        <a href={redirectTo} rel="noreferrer" target="_blank" style={{textDecoration: 'none'}}> 
                            <span className="donation-btn__container">
                                <DonationIcon className="donation-btn__logo" />
                                <p className="donation-btn__description">{title}</p>
                            </span>
                        </a> :
                        <span className="donation-btn__container">
                            <DonationIcon className="donation-btn__logo" />
                            <p className="donation-btn__description">{title}</p>
                        </span>
                }
            </button>            
        </>
    )
}
export default DonationBtn;
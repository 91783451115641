
import React from "react";
import TaskStats from "../TaskStats/TaskStats";

export default function TaskStatsPage(props) {
  return (
    <>
      <TaskStats getUrlData={props.getUrlData}/>
    </>
  );
};
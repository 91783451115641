import React, { useState } from "react"
import './TagsView.css'
import iconsHide from './static/iconsHide.svg'

export interface TagsViewProps {
  tags: any
}

function TagsView({ tags }: TagsViewProps) {
  const [isShow, setIsShow] = useState(false);

  return (
    <div>
      <div className="tags_section">
        {tags.length > 0 ?
          <div className="tag_name"># {tags[0]}</div>
          :
          <div className="tag_name_empty">нет метки</div>
        }

        {tags.length > 1 && !isShow &&
          <div className="dop_tag" onClick={() => setIsShow(true)}>+{tags.length - 1}</div>
        }
        {tags.length > 1 && isShow &&
          <div className="dop_tag_hide" onClick={() => setIsShow(false)}>
            <img src={iconsHide} alt=''/>
          </div>
        }
      </div>
      {isShow &&
        <div className="tags_list">
          {tags.map((x: any, i: number) =>
            i !== 0 &&
            <div className="tag_name"># {x}</div>
          )}
        </div>
      }
    </div>
  );
}

export default TagsView;


import { createStore, combineReducers, applyMiddleware } from "redux";
import { cashReducer } from "./cashReducer";
import { todosReducer } from "./todosReducer";
import { timeAllTodosReducer } from "./timeAllTodosReducer";
import { dataTodoIncomingReducer } from "./incomingTodosReducer";
import { dataTodoRemovedReducer } from "./removedTodosReducer";
import { dataTagReducer } from "./tagsReducer";
import { userParamsReducer } from "./userParamsReducer";
import { settingsReducer } from "./settingsReducer";
import { todosCompletedReducer } from "./completedTodosReducer";
import { checkListReducer } from "./chekListReducer";
import { taskCounterReducer } from "./StatisticsStore/TaskCounterReducer";
import { workTimeReducer } from "./WorkTimeStore/WorkTimeReducer";
import { percentCounterReducer } from "./StatisticsStore/TaskPercentCounterReducer";
import { tagsToTaskReducer } from "./TagsToTasksStore/TagsToTasksReducer";
import { stopWatchesDataReducer } from "./RunTimeTaskResults/RunTimeResults";
import { spentTimeReducer } from "./SpentTimeTask/SpentTimeReducer";
import  thunk  from "redux-thunk";
import notificationReduser from "./notificationReduser";
import { calendarTodosReducer } from "./calendarTodosReducer";
import { completedTodosOnRangeReducer } from "./StatisticsStore/CompletedTodosOnRangeReducer";
import { topForecastReducer } from "./StatisticsStore/TopForecastReducer";
import { topTagsReducer } from "./StatisticsStore/TopTagsReducer";
import { elapsedTimeToTodosReducer } from "./StatisticsStore/ElapsedTimeToTodosReducer";
import { onContextReduser } from "./contextMenuTaskReducer";
import taskTabelReducer from "../TaskStats/TaskTableSection/store/TaskTableSectionStore.ts";

const rootReducer = combineReducers({
    tags: dataTagReducer,
    cash: cashReducer,
    todos: todosReducer,
    time: timeAllTodosReducer,
    todosIncoming: dataTodoIncomingReducer,
    todosRemoved: dataTodoRemovedReducer,
    userParams: userParamsReducer,
    settings: settingsReducer,
    todosCompleted: todosCompletedReducer,
    checkList: checkListReducer,
    countTask: taskCounterReducer,
    workTime: workTimeReducer,
    countPercentTask: percentCounterReducer,
    tagsArray: tagsToTaskReducer,
    notification: notificationReduser,
    stopWatchesData: stopWatchesDataReducer,
    spentTime: spentTimeReducer,
    todosCalendar: calendarTodosReducer,
    completedTodosOnRange: completedTodosOnRangeReducer,
    topForecast: topForecastReducer,
    topTags: topTagsReducer,
    elapsedTimeToTodos: elapsedTimeToTodosReducer,
    onContextState: onContextReduser,
    taskTableState: taskTabelReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk));
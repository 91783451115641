import React from "react";
import './Button.css'


const Button = ({logo, title, stylesButton, stylesContainer, stylesLogo, stylesTitle, loading, loaderColor, disabled, onClickFunc}) => {

    return (
        <button className="button" style={stylesButton} onClick={onClickFunc} disabled={disabled}>
            {loading ? 
                <div className="centered-btn-spinner">
                    <div class="btn-lds-ring">
                        <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
                        <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
                        <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
                        <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
                    </div>
                </div> :
                <span className="button__container" style={stylesContainer}>
                    <span className="button__logo" style={stylesLogo}>{logo}</span>
                    <p className="button__title" style={stylesTitle}>{title}</p>
                </span>
            }   
        </button>
    )

}

export default Button;
import React, { useState } from "react";
import "./index.css";
import NoDataTemplate from "../NoDataTemplate/noDataTemplate";
import {ReactComponent as CloseInfo} from "../../icons/svg/cross_to_close_info.svg"


const TaskForecast = ({countPercentTask}) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    return (
        <div className="TaskForecast-container">
            <div className="TaskForecast-content">
                {
                    countPercentTask === -1 ?
                    <div className={isOpenInfo ? "info-hidden" : "loader"}/> : countPercentTask === null ?
                        <NoDataTemplate isOpenInfo={isOpenInfo} /> :
                        <div className={isOpenInfo ? "info-hidden" : "TaskForecast-data"}>
                            {isOpenInfo ?  <NoDataTemplate isOpenInfo={isOpenInfo}/> : <>{countPercentTask}</>}
                            <span className={isOpenInfo ? "info-hidden" : "stats-percent-span"}>%</span>
                        </div>
                    
                }
                <p className={isOpenInfo ? "info-hidden" : "TaskForecast-description"}>точность прогноза</p>
                {
                        window.screen.width <= '420' ? 
                        isOpenInfo ? <CloseInfo className="clicked-info__mobile" onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className="TaskCompleted-content__info" 
                            onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className={isOpenInfo ? "TaskCompleted-content__info moved-info" : "TaskCompleted-content__info"} 
                            onMouseEnter={() => setIsOpenInfo(true)}
                            onMouseLeave={() => setIsOpenInfo(false)}/>
                        
                    }
                    <div className={isOpenInfo ? "TaskForecast-content__info-container" : "info-hidden"}>
                        <p className="TaskForecast-content__info-container__description">
                            Насколько был точный прогноз по задачам за выбранный период.
                        </p>
                    </div>
            </div>
        </div>
    )
}

export default TaskForecast;
import React, { useEffect, useState } from "react";
import SubMenu from "../Submenu/SubmenuApp";
import TodosTodayApp from "../Todos/TodosTodayApp";
// import TaskSelection from "../GroupTaskEditing/TaskSelection";
import Dashboard from "../Dashboard/Dashboard";
import Button from "../UIcomponents/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import request from "../Requests/requests";
import { loadUserSettings } from "../store/settingsReducer";

export default function TodayTask({setIcon, updateModalState, updateTitleModal, updateDescriptionModal, updateButtonsModal, updateIsDisabledOutsideClose, updateIsDisabledModal}) {
    const dispatch = useDispatch();
    const userSettings = useSelector(state => state.settings.settings);
    const [loadingDecline, setLoadingDecline] = useState(false);
    const [loadingAccept, setLoadingAccept] = useState(false);
    const currentTimeZone = -new Date().getTimezoneOffset() / 60;
    console.log(userSettings);
    const stylesForBtnDiv = {
        width: '100%', 
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "flex-end", 
        alignItems: "center", 
        gap: '8px'
    }

    const handleDeclineNewTimeZone = () => {
        setLoadingDecline(true)
        request.put('/Settings/', {
            userId: localStorage.getItem('token'),
            currentTimeZone: currentTimeZone,
            timeZone: userSettings.timeZone
        })
        .then((response) => dispatch(loadUserSettings({...response.data, 
                                                        currentTimeZone: response.data.currentTimeZone, 
                                                        timeZone: userSettings.timeZone})))
        .then(() => setLoadingDecline(false))
        .then(() => updateModalState(false));
    } 

    const handleAddNewTimeZone = () => {
        setLoadingAccept(true)
        request.put('/Settings/', {
            userId: localStorage.getItem('token'),
            currentTimeZone: currentTimeZone,
            timeZone: currentTimeZone
        })
        .then((response) => dispatch(loadUserSettings({...response.data, 
                                                        currentTimeZone: response.data.currentTimeZone, 
                                                        timeZone: response.data.timeZone})))
        .then(() => setLoadingAccept(false))
        .then(() => updateModalState(false));
    }

    const dataToModal = {
        title: 'У вас изменился часовой пояс. Поменять его на новый?',
        disabledCloseBtn: true,
        description: <p>Новый часовой пояс <span style={{fontWeight: 600}}>GTM {currentTimeZone > 0 ? '+' + currentTimeZone : currentTimeZone}</span> — его можно будет изменить в настройках</p>,
        buttons: <div  style={stylesForBtnDiv}>
                    <Button title={'Оставить прошлый'}
                            loading={loadingDecline}
                            loaderColor={'#6724da transparent transparent transparent'} 
                            onClickFunc={handleDeclineNewTimeZone}
                            stylesButton={{padding: '8px', backgroundColor: 'transparent', border: '1px solid #E1E4ED'}}
                            stylesTitle={{color: '#232360', fontSize: '12px', lineHeight: '16px'}}
                    /> 
                    <Button title={'Да, сделать новый'}
                            loading={loadingAccept}
                            loaderColor={'#fff transparent transparent transparent'} 
                            onClickFunc={handleAddNewTimeZone}
                            stylesButton={{padding: '8px'}}
                            stylesTitle={{fontSize: '12px', lineHeight: '16px'}}
                    />
                </div>
    }
    console.log(userSettings);
    useEffect(() => {
        if ((Object.keys(userSettings).length !== 0) && (userSettings.currentTimeZone !== currentTimeZone)) {
            updateModalState(true)
            updateIsDisabledOutsideClose(true)
            updateTitleModal(dataToModal.title)
            updateDescriptionModal(dataToModal.description)
            updateButtonsModal(dataToModal.buttons)
            updateIsDisabledModal(true)
        }
    }, [currentTimeZone, userSettings, updateModalState,
        updateIsDisabledOutsideClose,
        dataToModal.title,
        dataToModal.description,
        dataToModal.buttons,
        updateTitleModal,
        updateDescriptionModal,
        updateButtonsModal,
        updateIsDisabledModal])

    // const clearSelect = event => {
    //     if(event.ctrlKey){
    //         return;
    //     }else{
    //         TaskSelection("clear");
    //         return;
    //     }
    // }
    return (
        <>
            <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                {

                }
                <div className="page-container">
                    <div className="page-info">
                        <Dashboard></Dashboard>
                    </div>
                    <div>
                        <TodosTodayApp setIcon={setIcon}></TodosTodayApp>
                    </div>
                </div>
            </div>
        </>
    )
}

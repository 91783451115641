import request from "../../Requests/requests";
import { getSpentTime } from "../SpentTimeTask/getSpentTime";
import { getTimeAllTodos } from '../getTimeAllTodos';

export function addRunTime(payload: { id: any; seconds: any}) {
    return async (dispatch: (arg0: { (dispatch: any): Promise<void>; (dispatch: any): Promise<void> }) => void) => {
        await request.post('/Time/RunTime/?TaskId=' + payload.id + '&UserId=' + localStorage.getItem('token') + '&Time=' + payload.seconds);
        dispatch(getSpentTime());
        dispatch(getTimeAllTodos());
        localStorage.removeItem('time');
    };
}
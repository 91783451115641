import React, { useState } from "react";
import "./index.css";
import "../loaderForStats.css"
import NoDataTemplate from "../NoDataTemplate/noDataTemplate";
import {ReactComponent as CloseInfo} from "../../icons/svg/cross_to_close_info.svg"


const SpentTime = (props) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    return (
        <div className="SpentTime-container">
            {/* <div className="SpentTime-loader">
                <span class="loader"></span>
            </div> */}
            
            <div className="SpentTime-content">
                <div className={isOpenInfo ? "info-hidden" : "SpentTime-content-data"}>
                {
                    props.elapsedTimeToTodos.length === 0 ?
                    <div style={{marginLeft: 'auto', marginRight: 'auto'}} className={isOpenInfo ? "info-hidden" : "loader"}/>: 
                    props.elapsedTimeToTodos.map((elapsedTimeToTodos) => 
                        <> 
                            {elapsedTimeToTodos.hours === 0 && elapsedTimeToTodos.minutes === 0 ?
                                <NoDataTemplate isOpenInfo={isOpenInfo}/> :
                                <p className="SpentTime-data">{elapsedTimeToTodos.hours} 
                                <span className="SpentTime-data-desc" style={{marginRight: '20px'}}>ч</span> 
                                {elapsedTimeToTodos.minutes} 
                                <span className="SpentTime-data-desc">мин</span>
                                </p>
                            }
                        </>
                    )
                }
                </div>
                <p className={isOpenInfo ? "info-hidden" : "SpentTime-description"}>затрачено времени</p>
                {
                        window.screen.width <= '420' ? 
                        isOpenInfo ? <CloseInfo className="clicked-info__mobile" onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className="TaskCompleted-content__info" 
                            onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className={isOpenInfo ? "TaskCompleted-content__info moved-info" : "TaskCompleted-content__info"} 
                            onMouseEnter={() => setIsOpenInfo(true)}
                            onMouseLeave={() => setIsOpenInfo(false)}/>
                        
                    }
                    <div className={isOpenInfo ? "SpentTime-content__info-container" : "info-hidden"}>
                        <p className="SpentTime-content__info-container__description">Сколько затрачено времени на задачи за выбранный период.</p>
                    </div>
            </div>
        </div>
    )
}

export default SpentTime;
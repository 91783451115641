import React from "react";
import { removeTodo } from "../../asyncActions/removeTodos";
import { completeTodo } from "../../asyncActions/comleteTodos";
import { notCompleteTodo } from "../../asyncActions/notCompletedTodo";
import { restoreTodo } from "../../asyncActions/restoreTodos";
import { useDispatch } from 'react-redux/es/exports';
import './index.css';
import { copyTodo } from "../../asyncActions/copyTodo";
import { useLocation, useNavigate } from "react-router-dom";

const DDSelectTaskMenu = ({updateDataBeforeClose, active, setActive, taskId, disabled, fromRemoved, fromCompleted}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    console.log('From DDSelect: ' + taskId);
    return (
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)}>
            <div className='task-menu__container'>
                <ul className="task-menu__list">
                    <li className="task-menu__item" style={fromRemoved ? {display: "none"} : {display: "flex"}} onClick={() => dispatch(removeTodo(taskId)) & navigate(`${location.state.path}`)} >
                        <span className="bg-task-menu-0 bg-task-menu-hov"/>
                        <p className="task-menu__description">
                            Удалить
                        </p>
                    </li>
                    <li className="task-menu__item" style={disabled ? {display: 'none'} : {display: 'flex'}}
                        onClick={(e) => updateDataBeforeClose()
                                    & dispatch(completeTodo({id: taskId})) & navigate(`${location.state.path}`)}>
                        <span className="bg-task-menu-1 bg-task-menu-hov"/>
                        <p className="task-menu__description">
                            Выполнить
                        </p>
                    </li>
                    <li className="task-menu__item" style={disabled ? {display: 'none'} : {display: 'flex'}} 
                        onClick={() =>  dispatch(copyTodo(taskId)) & navigate(`${location.state.path}`)}>
                        <span className="bg-task-menu-2 bg-task-menu-hov"/>
                        <p className="task-menu__description">
                            Дублировать
                        </p>
                    </li>
                    <li className="task-menu__item" style={disabled ? {display: 'flex'} : {display: 'none'}} 
                        onClick={() =>  fromCompleted ? dispatch(notCompleteTodo(taskId)) & navigate(`${location.state.path}`) : fromRemoved ? 
                            dispatch(restoreTodo(taskId)) & navigate(`${location.state.path}`) : null}>
                        <span className="bg-task-menu-3 bg-task-menu-hov"/>
                        <p className="task-menu__description">
                            Вернуть в работу
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DDSelectTaskMenu;
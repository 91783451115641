import React, { useState, useRef, useEffect } from "react";
import '../textEditor/quill.bubble.css';
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { addTodo } from "../asyncActions/addTodos";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { useLocation } from "react-router-dom";
import { useOutsideClick } from "../customHooks/useOutsideClick";
import { ReactComponent as DateCalendar } from "../icons/svg/ModalChangingTask/calendar.svg";
import { ReactComponent as TimeSvg } from "../icons/svg/timetotasklogo.svg";
import { ReactComponent as RemoveTag} from "../icons/svg/remove-tag-cross.svg";
import DDSelectDate from "../DropDownMenu/SelectDateCalendar/DDSelectDate";
import DDSelectPriority from "../DropDownMenu/SelectPriority/DDSelectPriority";
import DDSelectTags from "../DropDownMenu/SelectTags/DDSelectTags";
import DDSelectTimeToTask from "../DropDownMenu/SelectTimeTotask/DDSelecTimeToTask";
import ConverterUTC from "../ConverterUTC/ConverterUTC";

export default function TodosAddApp({autofocus, updateTaskList, datePayload, dateFromCalendarPage, ...props}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const settingUser = useSelector(state => state.settings.settings)
    const [autoFocus, setAutoFocus] = useState(autofocus);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState();
    const [date, setDate] = useState(datePayload);
    const [time, setTime] = useState();
    const [tagList, setTagList] = useState([]);
    // const [tagListName, setTagListName] = useState(null);
    const [priority, setPriority] = useState(3);
    const [timeToTaskHours, setTimeToTaskHours] = useState("");
    const [timeToTaskMinutes, setTimeToTaskMinutes] = useState("");
    const [currentLenghtOfTitle, setCurrentLenghtOfTitle] = useState();
    const [isActiveCounter, setIsActiveCounter] = useState(false);
    // const [inputHeightOfDescription, setInputHeightOfDescription] = useState();
    // const [inputHeightOfTitle, setInputHeightOfTitle] = useState();
    // const [overflowDescription, setOverflowDescription] = useState();
    const maxLenghtOfTitle = 150;
    const hours = 0;
    const minutes = 0;
    const [diasbledBtn, setDisabledBtn] = useState(true)
    const classPriorityArray = ['priority-span-addTask-1', 'priority-span-addTask-2', 'priority-span-addTask-3', 'priority-span-addTask-0']
    const dateToday = ConverterUTC({method: 'object', settingsUser: settingUser});
    const dateTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substring(0,10).replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1');
    const dateNormalize = dateToday.toLocaleDateString();
    const [menuActivDatePicker, setMenuActivDatePicker] = useState(false);
    const [menuActivPriorityPicker, setMenuActivPriorityPicker] = useState(false);
    const [menuActivTagsPicker, setMenuActivTagsPicker] = useState(false);
    const [menuActivTimePicker, setMenuActivTimePicker] = useState(false);
    const [isEmptyDateValue, setIsEmptyDateValue] = useState(true);
    const [isEmptyTimeValue, setIsEmptyTimeValue] = useState(true);
    const [scrolledY, setScrolledY] = useState(0);
    const reactQuillRef = React.useRef();
    const refCloseDatePicker = useRef();
    useOutsideClick(refCloseDatePicker, () => setMenuActivDatePicker(false), menuActivDatePicker);
    const refCloseTimePicker = useRef();
    useOutsideClick(refCloseTimePicker, () => setMenuActivTimePicker(false), menuActivTimePicker);
    const refClosePriorityPicker = useRef();
    useOutsideClick(refClosePriorityPicker, () => setMenuActivPriorityPicker(false), menuActivPriorityPicker);
    const refCloseTagsPicker = useRef();
    useOutsideClick(refCloseTagsPicker, () => setMenuActivTagsPicker(false), menuActivTagsPicker);

    const updateDataDate = (payload) => {
        setDate(payload.date)
    }

    const updateDataTime = (payload) => {
        setTime(payload.time)
    }

    const updateTagList = (payload) => {
        setTagList([...tagList, payload])
    }

    const updateDataPriority = (payload) => {
        setPriority(payload.priority)
    }

    const updateDataTimeToTaskHours = (payload) => {
        setTimeToTaskHours(payload.timeToTaskHours)
    }

    const updateDataTimeToTaskMinutes = (payload) => {
        setTimeToTaskMinutes(payload.timeToTaskMinutes)
    }

    useEffect(() => {
        const handleScrolledY = () => {
            setScrolledY(window.scrollY);
        };

        window.addEventListener('scroll', handleScrolledY);

        return () => {
            window.removeEventListener('scroll', handleScrolledY);
        };
    }, [])

    useEffect(() => {
        if (autoFocus) {
            reactQuillRef.current.focus()
        } 
    }, [autoFocus])

    useEffect(() => {
        if ((date || time !== undefined) || (date === '')) {
            setIsEmptyDateValue(false)
        } else {
            setIsEmptyDateValue(true)
        }
    }, [date, time])

    useEffect(() => {
        if ((timeToTaskHours || timeToTaskMinutes !== undefined) && (timeToTaskHours || timeToTaskMinutes !== '')) {
            setIsEmptyTimeValue(false)
        } else {
            setIsEmptyTimeValue(true)
        }
    }, [timeToTaskHours, timeToTaskMinutes])

    function checkValueInputDate() {
        if ((date === '') && (time !== undefined)) {
            return 'Без срока ' + time
        } else if ((date === '') && (time === undefined)) {
            return 'Без срока'
        } else if (((date !== undefined) && (date === dateNormalize)) && (time !== undefined)) {
            return 'Сегодня  ' + time 
        } else if (((date !== undefined) && (date === dateTomorrow)) && (time !== undefined)) {
            return 'Завтра  ' + time
        } else if ((((date !== undefined) && (date !== dateNormalize)) && (date !== dateTomorrow)) &&  (time !== undefined)){
            return date + '  ' + time
        } else if (((date !== undefined) && (date === dateNormalize)) && (time === undefined)) {
            return 'Сегодня'
        } else if (((date !== undefined) && (date === dateTomorrow)) && (time === undefined)) {
            return 'Завтра'
        } else if ((((date !== undefined) && (date !== dateNormalize)) && (date !== dateTomorrow)) &&  (time === undefined)) {
            return date
        } else if ((date === undefined) && (time !== undefined) && (location.pathname === '/')) {
            return 'Сегодня  ' + time
        } else if ((date === undefined) && (time !== undefined)) {
            return time
        }
    }

    const checkLenghtOfTitle = (e) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() < 100) {
            return setIsActiveCounter(false);
        } else if (unprivilegedEditor.getLength() === 100) {
            return setCurrentLenghtOfTitle(unprivilegedEditor.getLength()) & setIsActiveCounter(true);
        } else if ((unprivilegedEditor.getLength() <= maxLenghtOfTitle)) {
            return setCurrentLenghtOfTitle(unprivilegedEditor.getLength());
        } else if ((unprivilegedEditor.getLength() >= maxLenghtOfTitle && e.key !== 'Backspace')) {
            e.preventDefault()
        }
    }

    const unDisableButtonToPushTask = () => {
        if (title.replace(/(<([^>]+)>)/gi, '') === '') {
            setDisabledBtn(true)
        } else {
            setDisabledBtn(false)
        }
    }
    function selectedTimeToTask() {
        if ((timeToTaskHours === undefined) && (timeToTaskMinutes === undefined)) {
            return ('');
        } else if (((timeToTaskHours === undefined) || (timeToTaskHours === '')) && (timeToTaskMinutes !== undefined) && (timeToTaskMinutes !== '')) {
            return (timeToTaskMinutes + ' мин');
        } else if (((timeToTaskMinutes === undefined) || (timeToTaskMinutes === '')) && (timeToTaskHours !== undefined) && (timeToTaskHours !== '')) {
            return (timeToTaskHours + ' ч');
        } else if ((timeToTaskHours === '') && (timeToTaskMinutes === '')){
            return ('');
        } else if ((timeToTaskHours === undefined) && (timeToTaskMinutes === undefined)){
            return ('');
        } else if ((timeToTaskHours === undefined) && (timeToTaskMinutes === '')){
            return ('');
        }else if ((timeToTaskHours === '') && (timeToTaskMinutes === undefined)){
            return ('');
        } else {
            return (timeToTaskHours + ' ч ' + timeToTaskMinutes + ' мин');
        }
    }

    function handleSubmitOfCombination(e) {
        if (e.ctrlKey && e.keyCode === 13) {
            e.preventDefault()
            if (diasbledBtn === false){
                    handleSubmit(e)
                }
    }}
    function handleSubmit(e) {
        if ((location.pathname === '/')) {
            const obj = {
                title: title.replace(/(<([^>]+)>)/gi, ''),
                description: description,
                date: date,
                time: time,
                priority: priority,
                hours: Number(timeToTaskHours) ?? 0,
                minutes: Number(timeToTaskMinutes) ?? 0,
                tagsId: tagList.map((tagId) => tagId.tagId),
                dateFrom: dateFromCalendarPage?.toDateString()
            }
            if(obj.title === ""){
                return;
            }
            dispatch(addTodo(obj));
            updateTaskList();
        } else {
            const obj = {
                title: title.replace(/(<([^>]+)>)/gi, ''),
                description: description,
                date: date,
                time: time,
                priority: priority,
                hours: hours,
                minutes: minutes,
                tagsId: tagList.map((tagId) => tagId.tagId),
                dateFrom: dateFromCalendarPage?.toDateString()
            }
            if(obj.title === ""){
                return;
            }
            dispatch(addTodo(obj));
        }
        dispatch(getTimeAllTodos());
        props.updateData(false)
        }

    function removeTagFromTagList(index) {
        setTagList(tagList.filter(tagRemoved => tagRemoved.tagId !== index));
    }
    
    function stopEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }
    const closeAddApp = (e) => {
        if (e.keyCode === 27) {
            props.updateData(false)
        }
    }
        return (
            <div className="add-task__container" onKeyDown={(e) => closeAddApp(e)}>
                <form onSubmit={handleSubmit} onKeyDown={e => handleSubmitOfCombination(e)} className="add-task__form">
                    <div className="add-task__container__input-field">
                            <ReactQuill
                                ref={reactQuillRef} 
                                theme="bubble"
                                value={title}
                                id="title"
                                name="title"
                                required
                                className="add-task__input add-task__input-title"
                                placeholder="Название задачи"
                                onChange={setTitle}
                                onKeyUp={e => handleSubmitOfCombination(e)}
                                onKeyDown={(e) => checkLenghtOfTitle(e) & unDisableButtonToPushTask()}
                                onBlur={() => setAutoFocus(false)}
                            />
                            <ReactQuill
                                theme="bubble"
                                value={description}
                                id="description"
                                name="description"
                                
                                className="add-task__input add-task__input-description"
                                placeholder="Описание задачи"
                                onChange={setDescription}
                            />
                    </div>
                    <hr className="separator-add-task" />
                    {/* <div className="separator-add-task" /> */}
                    <div className="add-task__change-group">
                        <div ref={refCloseDatePicker} 
                            className="add-task__change-group__data" 
                            style={menuActivDatePicker ?
                                {border: '1px solid #5932EA'} : null 
                                || 
                                isEmptyDateValue ? 
                                null : {border: '1px solid #5932EA'}} 
                            onClick={() => setMenuActivDatePicker(!menuActivDatePicker)}>
                            <DateCalendar className="add-task__calendar-svg" 
                                style={menuActivDatePicker ?
                                    {stroke: '#5932EA'} : null 
                                    || 
                                    isEmptyDateValue ? null : {stroke: '#5932EA'}}/>
                            <input 
                                type="text"
                                readOnly
                                placeholder={location.pathname === '/' ? 'Сегодня' : 'Срок выполнения'}  
                                className="priority__input-date" 
                                style={isEmptyDateValue ? null : {color: 'rgb(89, 50, 234)'}}
                                value={checkValueInputDate()}
                                onKeyDown={(e) => stopEnter(e)}/>
                            
                        </div>
                        <div ref={refCloseTimePicker} 
                            className="add-task__change-group__time" 
                            style={menuActivTimePicker ?
                                {border: '1px solid #5932EA'} : null 
                                || 
                                isEmptyTimeValue ? 
                                null : {border: '1px solid #5932EA'}} 
                            onClick={() => setMenuActivTimePicker(!menuActivTimePicker)}>
                            <TimeSvg className="add-task__time-svg" 
                                style={menuActivTimePicker ?
                                    {fill: '#5932EA'} : null 
                                    || 
                                    isEmptyTimeValue ? null : {fill: '#5932EA'}}/>
                            <input
                                minLength='5'
                                maxLength='5' 
                                type="text"
                                readOnly 
                                placeholder='Время на задачу'  
                                className="priority__input-time"
                                style={isEmptyTimeValue ? null : {color: 'rgb(89, 50, 234)'}}
                                value={selectedTimeToTask()}/>
                            
                            
                        </div>
                        <div className='add-task__change-group__priority'
                            ref={refClosePriorityPicker} 
                            onClick={() => setMenuActivPriorityPicker(!menuActivPriorityPicker)}
                            style={menuActivPriorityPicker && window.screen.width < 480 ?
                                {border: '1px solid #5932EA'} : null}>
                            <span
                                title="Приоритет для задачи"
                                className={classPriorityArray[priority]}/>
                            <p>Приоритет</p>
                            
                        </div>
                        <div className='add-task__change-group__tags'
                            ref={refCloseTagsPicker}
                            onClick={() => setMenuActivTagsPicker(!menuActivTagsPicker)}
                            style={menuActivTagsPicker && window.screen.width < 480 ?
                                {border: '1px solid #5932EA'} : null}
                            > 
                            <span 
                                title="Добавить метку к задаче"
                                className="add-task__add-tags-to-task"
                                style={tagList === null ? 
                                    null : tagList.length > 0 ? 
                                    {backgroundColor: '#5932EA'} : null}
                            />
                            <p className="add-task__change-group__tags-description">Метки</p>
                               
                        </div>
                    </div>
                        {/* <div className={menuActivDatePicker ? 'menu active' : 'menu'}> */}
                            <DDSelectDate
                                addTaskButtons
                                deadlineinput
                                scrolledY={scrolledY}
                                typeOfCalendar={1} 
                                active={menuActivDatePicker}
                                time={time} 
                                setActive={setMenuActivDatePicker} 
                                updateDataDate={updateDataDate} 
                                updateDataTime={updateDataTime}
                                modalShow={false}/>
                        {/* </div> */}
                        <div className={menuActivTimePicker ? 'menu active' : 'menu'}>
                            <DDSelectTimeToTask
                                active={menuActivTimePicker} 
                                setActive={setMenuActivTimePicker}
                                timeToTaskHours={timeToTaskHours}
                                timeToTaskMinutes={timeToTaskMinutes}
                                updateDataTimeToTaskHours={updateDataTimeToTaskHours}
                                updateDataTimeToTaskMinutes={updateDataTimeToTaskMinutes}/>
                        </div>
                            <div className={menuActivPriorityPicker ? 'menu active' : 'menu'} style={window.screen.width < 480 ? {right: '0%', left: '0%', transform: 'translate(0%, 0%)'} : null} onClick={() => setMenuActivPriorityPicker(false)}>
                                <DDSelectPriority
                                    path={'addTask'} 
                                    active={menuActivPriorityPicker} 
                                    setActive={setMenuActivPriorityPicker} 
                                    updateDataPriority={updateDataPriority}/>
                            </div>
                            <div className={menuActivTagsPicker ? 'menu active' : 'menu'} onClick={() => setMenuActivTagsPicker(false)}>     
                            <DDSelectTags
                                    path={'addTask'} 
                                    active={menuActivTagsPicker} 
                                    setActive={setMenuActivTagsPicker}
                                    updateTagList={updateTagList}
                                    tagList={tagList}
                                    removeTagFromTagList={removeTagFromTagList}/>
                            </div>
                        <div className="btn-container">
                            <button 
                                className="close-add-task-form"
                                onClick={() => props.updateData(false)}>
                                    Отмена
                            </button>
                            <input 
                                type='submit' 
                                className="btn-add-task" 
                                value='Добавить задачу'
                                disabled = {diasbledBtn}
                                onClick={() => setTimeout(() => { props.updateData(false)}, 100) } />
                        </div>
                        <div className="tagsList-container">
                            <ul className="tagsList-list">
                                {tagList.length ? 
                                    tagList.map((el, index) => {
                                        return <li className="tagsList-item" key={index}>
                                            <span className="tagsList-item__bg"/>
                                            <div className="tagsList-item__container">
                                                <p className="tagsList-item__name">{el.tagName}</p>
                                                <RemoveTag className="tagsList-item__remove-item"
                                                     onClick={() => removeTagFromTagList(el.tagId)} />
                                            </div></li>}) : 
                                    null}
                            </ul>
                        </div>
                        
                        <p className={isActiveCounter ? 'alertCounterLengthOfTitle' : 'hide'}>
                            Лимит символов: {currentLenghtOfTitle} из 150
                        </p>
                    
                </form> 
            </div>
        )
    }

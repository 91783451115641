import { useEffect, useState } from 'react';
import Calendar from '../../Calendar/index';
import { currentDateContext } from "../../Context/currentDateContext";
import { currentDateRangeContext } from "../../Context/currentDateRangeContext";
import './index.css';
import Deadlineinput from './DeadlineInput/Deadlineinput';
import AddTaskButtons from './AddTaskButtons/AddTaskButtons';
import SmartRecognition from './SmartRecognition/SmartRecognition';
import StatisticsButton from './StatisticsButton/StatisticsButton';
import ConverterUTC from '../../ConverterUTC/ConverterUTC';
import { useSelector } from 'react-redux';

const DDSelectDate = ({smartRecognition, 
    addTaskButtons, 
    deadlineinput, 
    statisticsButton, 
    active,
    time,
    dataTask,
    setActive,
    updateDataDate,
    updateDataTime,
    onChangePeriodOfStatistic,
    modalShow,
    typeOfCalendar,
    currentRange,
    setCurrentRange,
    updateSelectedDataRange,
    stylesCalendar,
    stylesCalendarMonthDate,
    stylesCalendarNavi,
    stylesCalendarTable,
    calendarPosition,
    disabledItem,
    scrolledY,
    ...props}) => {
    
    function pushDataTime(value) {
        updateDataTime({
            time: value
        })
    }
    const settingsUser = useSelector(state => state.settings.settings)
    const [splitedTime, setSplitedTime] = useState('');
    const [removeBtnTrigger, setRemoveBtnTrigger] = useState(false);
    const [isActiveNoData, setIsActiveNoData] = useState(false);
    const [isActiveToday, setIsActiveToday] = useState(false);
    const [isActiveTomorrow, setIsActiveTomorrow] = useState(false);
    const [isActiveWeek, setIsActiveWeek] = useState(false);
    const [isActiveMonth, setIsActiveMonth] = useState(false);
    const [isFocus, setIsFocus] = useState(false)
    const dateToday = ConverterUTC({method: 'object', settingsUser: settingsUser})
    const [dateToContext, setDateToContext] = useState(dateToday.toLocaleDateString())

    useEffect(() => {
        setSplitedTime(time !== null ? time :  '')
    }, [time])

    useEffect(() => {
        if(!time) {
            setRemoveBtnTrigger(false)
        } else {
            setRemoveBtnTrigger(true)
        }
    }, [time])

    function setDateToday() {
        // setIsActiveToday(!isActiveToday)
        setDateToContext(dateToday.toLocaleDateString())
        updateDataDate({
            date: dateToday.toLocaleDateString()
        })
    }
    function setDateTomorrow() {
        const dateTomorrow = new Date()
        dateTomorrow.setDate(dateTomorrow.getDate() + 1)
        setDateToContext(dateTomorrow.toLocaleDateString())
        updateDataDate({
            date: dateTomorrow.toLocaleDateString()
        })
    }
    
    function setNoDate() {
        setDateToContext(isActiveNoData ? dateToday.toLocaleDateString() : '')
        updateDataDate({date: isActiveNoData ? dateToday.toLocaleDateString() : ''}) 
    }

    function checkWords(e) {
        if ("0123456789".indexOf(e.key) !== -1) {
            splitTime(e)
        } else {
            e.preventDefault()
        }
    }

    function splitTime(e) {
        if(e.target.value === '') {
            setRemoveBtnTrigger(false)
        } else {
            setRemoveBtnTrigger(true)
        }
        
        const split_1 = e.target.value.length ? e.target.value.replace(/:/g, '').match(/.{1,2}/g).join(':') : '';
        if (split_1.slice(0,2) >= 24) {
            setSplitedTime(split_1.replace(split_1.slice(0,2), '23'))
            pushDataTime(split_1.replace(split_1.slice(0,2), '23'))
        } else if (split_1.slice(3,5) >= 60) {
            setSplitedTime(split_1.replace(split_1.slice(3,5), '59'))
            pushDataTime(split_1.replace(split_1.slice(3,5), '59'))
        } else {
            setSplitedTime(split_1)
            pushDataTime(split_1)
        }
        
    }

    function removePushedDataTime() {
        pushDataTime("")
        setSplitedTime("")
        setRemoveBtnTrigger(false)
    }

    function hideRemoveBtn() {
        if(splitedTime === '') {
            setRemoveBtnTrigger(false)
            setIsFocus(false)
        } else if (splitedTime !== ''){
            if (!splitedTime.slice(3,5)) {
                setSplitedTime(splitedTime +':00')
                pushDataTime(splitedTime+ ':00')
                setIsFocus(false)
            } else if (!splitedTime.slice(4,5)) {
                setSplitedTime(splitedTime +'0')
                pushDataTime(splitedTime+ '0')
                setIsFocus(false)
            } else {
                setRemoveBtnTrigger(true)
                setIsFocus(false)
            }
        }
    }
    useEffect(() => {
        const dateTomorrow = new Date()
        dateTomorrow.setDate(dateToday.getDate() + 1)
        if (dateToContext === dateToday.toLocaleDateString()) {
            setIsActiveToday(true);
        } else {
            setIsActiveToday(false);
        }

        if (dateToContext === dateTomorrow.toLocaleDateString()) {
            setIsActiveTomorrow(true);
        } else {
            setIsActiveTomorrow(false);
        }

        if (dateToContext === '') {
            setIsActiveNoData(true);
        } else {
            setIsActiveNoData(false);
        }
        console.log(dateToContext, dateToday);
        // eslint-disable-next-line
    }, [dateToContext])

    return(
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)} >
            <div className='calendar__container'
                style={scrolledY === 0 && window.screen.width > 576 ? {top: '-110px', right: '20px'} : calendarPosition ? {top: `${calendarPosition.top}`, 
                                            right: `${calendarPosition.right}`, 
                                            left: `${calendarPosition.left}`,
                                            transform: `${calendarPosition.transform}`} : {top: '15px'}}  
                onClick={e => e.stopPropagation()}
                >
                <ul className='calendar__list'>
                    {
                        smartRecognition && 
                            <SmartRecognition title='Введите срок выполнения' />
                    }
                    {
                        statisticsButton && 
                            <StatisticsButton isActiveToday={isActiveToday} 
                                isActiveWeek={isActiveWeek} 
                                isActiveMonth={isActiveMonth}
                                setIsActiveToday={setIsActiveToday}
                                setIsActiveWeek={setIsActiveWeek}
                                setIsActiveMonth={setIsActiveMonth} 
                                onChangePeriodOfStatistic={onChangePeriodOfStatistic} /> 
                    }
                    {
                        addTaskButtons && 
                            <AddTaskButtons isActiveToday={isActiveToday}
                                isActiveTomorrow={isActiveTomorrow}
                                disabledItem={disabledItem}
                                isActiveNoData={isActiveNoData}
                                setIsActiveNoData={setIsActiveNoData}
                                setDateToday={setDateToday}
                                setNoDate={setNoDate}
                                setIsActiveToday={setIsActiveToday}
                                setIsActiveTomorrow={setIsActiveTomorrow}
                                setDateTomorrow={setDateTomorrow}/>
                    }
                    {
                        deadlineinput && 
                            <Deadlineinput isFocus={isFocus}
                                removeBtnTrigger={removeBtnTrigger}
                                time={time}
                                checkWords={checkWords}
                                splitTime={splitTime}
                                setIsFocus={setIsFocus}
                                hideRemoveBtn={hideRemoveBtn}
                                removePushedDataTime={removePushedDataTime} />
                    }
                    <li className='calendar__item'>
                        {typeOfCalendar === 3 ?
                        <currentDateRangeContext.Provider value={currentRange}>
                            <Calendar updateSelectedDataRange={updateSelectedDataRange} 
                                typeOfCalendar={typeOfCalendar}
                                stylesCalendar={stylesCalendar}
                                stylesCalendarMonthDate={stylesCalendarMonthDate}
                                stylesCalendarNavi={stylesCalendarNavi}
                                stylesCalendarTable={stylesCalendarTable}/>
                        </currentDateRangeContext.Provider>   
                        :
                        <currentDateContext.Provider value={dateToContext}>
                            <Calendar setDateToContext={setDateToContext}
                                        isActiveToday={isActiveToday}
                                        isActiveTomorrow={isActiveTomorrow} 
                                        updateDataDate={updateDataDate} 
                                        disabledPastDays 
                                        setActive={setActive} 
                                        typeOfCalendar={typeOfCalendar}/>
                        </currentDateContext.Provider>
                    }
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default DDSelectDate;
import React, { useEffect, useState, useRef } from "react";
import Loader from "../Loader/Lodaer";
import TodoList from "./TodosList";
import TodosAddApp from "./TodosAddApp";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTodos } from '../asyncActions/getTodosToday';
import Filter from "../Filter/Filter";
import { ReactComponent as ModalTitleImg } from "../icons/svg/ModalChangingTask/TitleImg/myDay.svg";
import { ReactComponent as AddTaskPlus } from "../icons/svg/add-task-plus.svg";
import { ReactComponent as AddTaskPlusMobile } from "../icons/svg/add-task-plus__mobile.svg";
import { addOnContextState } from "../store/contextMenuTaskReducer";
import { useOutsideClick } from "../customHooks/useOutsideClick";
import { findActiveTimer } from "../asyncActions/TimerActions/findActiveTimer";
import { getTimeAllTodos } from "../asyncActions/getTimeAllTodos";
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";

export default function TodoListApp({ setIcon }) {
    const dispatch = useDispatch();
    const todos = useSelector(state => state.todos.todos);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [connection, setConnection] = useState(null);
    const date = new Date();
    const refCloseAdd = useRef();
    useOutsideClick(refCloseAdd, () => setIsOpen(false), isOpen);
    const updateData = (value) => {
        setIsOpen(value)
    }

    const url = (process.env.REACT_APP_API_BASEURL_HUB ?? 'https://api-demo.timetask.ru/') + 'TaskUpdateHub';

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect()
            .build()
        setConnection(newConnection)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on("Receive", (userId) => {
                        if (userId === localStorage.getItem('token')) {
                            dispatch(getTodos())
                            dispatch(getTimeAllTodos());
                            dispatch(getCountTime());
                        }
                    });
                })
                .catch(err => console.log('Error while establishing connection: ', err));
        }
    }, [connection, dispatch])

    const updateTaskList = () => {
        connection.invoke("Send", localStorage.getItem('token'))
            .catch(err => console.error(err));
    }


    useEffect(() => {
        dispatch(getTodos());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        dispatch(findActiveTimer())
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        setLoading(false);
        setIcon(<ModalTitleImg style={{ width: '14px' }} />)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        document.addEventListener("keydown", checkFastCombinations);
        return () => {
            document.removeEventListener("keydown", checkFastCombinations);
        };
    });
    function checkFastCombinations(e) {
        if (e.altKey && e.keyCode === 77) { //shift + m - открыть/закрыть меню добавления задачи
            setIsOpen(!isOpen)
        }
        if (e.keyCode === 27) {
            setIsOpen(false)
        }
    }

    return (
        <div className="todo-list">
            <div className="todo-list__container">

                <div id='todosMenu' className="task-actions-container to_the_end">
                    {/* <GroupChangeMenu /> */}
                    <div className="d-flex">
                        <div className={isOpen ? 'addTaskBtn__hide' : 'addTaskBtn'} onClick={() => setIsOpen(!isOpen)}>
                            <AddTaskPlus className="addTaskPlus" />
                            <AddTaskPlusMobile className="addTaskPlus-Mobile" />
                        </div>
                        <Filter />
                    </div>
                </div>
                {isOpen && (
                    <div>
                        <TodosAddApp datePayload={date.toLocaleDateString()} updateTaskList={updateTaskList} autofocus={true} updateData={updateData}></TodosAddApp>
                    </div>
                )}

                {
                    loading ? <Loader /> :
                        <ul className="todo-list__list">
                            {todos.length ? (<div>
                                {todos.map((todos, index) => {
                                    dispatch(addOnContextState({ contextIsOpen: false, contextTaskId: todos.id }))
                                    return (

                                        <TodoList
                                            updateTaskList={updateTaskList}
                                            completeBtn

                                            timerDisplay
                                            fromToday
                                            todos={todos}
                                            key={index} />

                                    )
                                })}
                            </div>) : loading ? null : (<p className="todo-list__is-null">Ваш список задач на сегодня пуст</p>)}
                        </ul>
                }
            </div>
        </div>
    )
}

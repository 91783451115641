import React from "react";
import TagsApp from "../Tags/TagsApp";
import SubMenu from "../Submenu/SubmenuApp";
import { ReactComponent as HashLogo } from "../icons/svg/TagsDDSelect/Hash.svg";

export default function IncomingTodosPage() {
    return (
        <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="tags-container">
                    <div className="page-info">
                        <HashLogo className="tags-hash__logo" style={{marginRight: '8px'}}/>
                        <h1 className="page-title"> Метки</h1>
                    </div>
                    <div>
                        <TagsApp></TagsApp>
                    </div>
                </div>
            </div>
    )
}

import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useOutsideClick } from "./customHooks/useOutsideClick";
import CompletedTodosPage from './Pages/CompletedTasksPage/CompletedTodosPage';
import DeletedTodosPage from './Pages/DeletedTasksPage/DeletedTodosPage';
import IncomingTodosPage from './Pages/IncomingTasksPage/IncomingTodosPage';
import LoginPage from './Pages/LoginPage';
import RegisterPage from './Pages/RegisterPage';
import TodayTask from './Pages/TodayTodosPage';
import OffcanvasMobile from './OffcanvasMobile/offcanvasMobileApp';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import LabelPage from './Pages/LabelPage';
import Settings from './Pages/SettingsPage/SettingsPage';
import ForgotPassword from './Pages/ForgotPassword';
import { getAvatar} from "./asyncActions/avatarService";
import logo from './icons/svg/logo_timetask.svg';
import DDMenu from './DropDownMenu/DDMenu';
import './App.css';
import ResetPassword from './Pages/ResetPassword';
// import TaskSelection from "./GroupTaskEditing/TaskSelection";
import CalendarPage from './Pages/CalendarPage/CalendarPage';
import SuccessPage from './Pages/SuccessPage/SuccessPage.tsx';
import { ReactComponent as BurgerMenu } from "../src/icons/svg/menu_ic/burger_menu_mobile.svg";
import { ReactComponent as ArrowDown } from "../src/icons/svg/menu_ic/arrow_down_pagePicker.svg";
import DDselectPage from './DropDownMenu/SelectPageMobile/DDselectPage';
import { ReactComponent as CalendarSvg } from "./icons/svg/submenu_ic/calendar-active.svg";
import { ReactComponent as TodaySvg } from "./icons/svg/submenu_ic/element-1-active.svg";
import { ReactComponent as IncomingSvg } from "./icons/svg/submenu_ic/filled-active.svg";
import EditModalTask from './ModalWindow/EditTaskModal/EditModalTask';
import ModalWindow from './ModalWindow/ModalWindow/ModalWindow';
import Button from './UIcomponents/Button/Button';
import request from './Requests/requests';
import { loadUserSettings } from './store/settingsReducer';
import Privacy from './Privacy/privacy';

function App() {
  localStorage.setItem('interceptorTry', 'false');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const ref = useRef();
  const [menuActiv, setMenuActiv] = useState(false);
  const [menuPage, setMenuPage] = useState(false);
  const [pageName, setPageName] = useState({pageName: 'Мой день', index: 0});
  const [windowScreen, setWindowScreen] = useState(window.screen.width);
  const [modalShow, setModalShow] = useState(false);
  const [isDisabledHeaderBtns, setIsDisabledHeaderBtns] = useState(true);
  const [titleModal, setTitleModal] = useState('');
  const [descriptionModal, setDescriptionModal] = useState('');
  const [logoModal, setLogoModal] = useState(null);
  const [buttonsModal, setButtonsModal] = useState(null);
  const [isDisabledCloseBtn, setIsDisabledCloseBtn] = useState(false);
  const [isDisabledOutsideClose, setIsDisabledOutsideClose] = useState(false);
  const [editTaskModalIcon, setEditTaskModalIcon] = useState();
  const updateModalState = (payload) => {
    setModalShow(payload)
  }
  const updateTitleModal = (payload) => {
    setTitleModal(payload)
  }
  const updateDescriptionModal = (payload) => {
    setDescriptionModal(payload)
  }
  const updateLogoModal = (payload) => {
    setLogoModal(payload)
  }
  const updateButtonsModal = (payload) => {
    setButtonsModal(payload)
  }
  const updateIsDisabledModal = (payload) => {
    setIsDisabledCloseBtn(payload)
  }
  const updateIsDisabledOutsideClose = (payload) => {
    setIsDisabledOutsideClose(payload)
  }
  const location = useLocation();
  const updatePageName = (payload) => {
    setPageName({
      pageName: payload.pageName,
      index: payload.index
    })
  }
  const arraySvg = [
    <TodaySvg style={{width:'16px', height: '16px'}}/>, 
    <CalendarSvg style={{width:'16px', height: '16px'}}/>, 
    <IncomingSvg style={{width:'16px', height: '16px'}}/>
  ];

  const arrayPages = [
    'Мой день',
    'Календарь',
    'Входящие задачи'
  ]
  const arrayLink = ['/', '/calendar', '/viewlist'];
  const stylesButtonLogin = {padding: '10px 20px'}
  const stylesTitleLogin = {lineHeight: 'normal'}
  const stylesButtonReg = {padding: '10px 25px', backgroundColor: 'transparent', border: '1px solid #5932EA'}
  const stylesTitleReg = {color: '#5932EA', lineHeight: 'normal'}

  let avaUrl = useSelector(state => state.settings.avatarUrl);
  useOutsideClick(ref, () => setMenuActiv(false), menuActiv);
  // const clearSelect = event => {
  //   if(event.ctrlKey){
  //       return;
  //   }else{
  //       TaskSelection("clear");
  //       return;
  //   }
  // }
  const refCloseSelectPage = useRef();
  useOutsideClick(refCloseSelectPage, () => setMenuPage(false), menuPage);

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Мой день'
        break;
      case '/calendar':
        document.title = 'Календарь'
        break;
      case '/viewlist':
        document.title = 'Входящие задачи'
        break;
      case '/profile':
        document.title = 'Профиль'
        break;
      case '/settings':
        document.title = 'Настройки'
        break;
      case '/statistics':
        document.title = 'Статистика'
        break;
      case '/completed':
        document.title = 'Выполненные'
        break;
      case '/removed_todo':
        document.title = 'Корзина'
        break;
      case '/tags':
        document.title = 'Метки'
        break;
      case '/login':
        document.title = 'Вход - Timetask'
        break;
      case '/login/':
        document.title = 'Вход - Timetask'
        break;
      case '/register':
        document.title = 'Регистрация - Timetask'
        break;
      case '/forgot_password':
        document.title = 'Восстановление пароля'
        break;
      default:
        document.title = 'Timetask'
        break;
    }
  })

  useEffect(() => {
    const handleUpdateScreenWidth = () => {
      setWindowScreen(window.screen.width)
    }

    window.addEventListener('resize', handleUpdateScreenWidth);

    return () => {
      window.removeEventListener('resize', handleUpdateScreenWidth);
    };
  }, [windowScreen])

  useEffect(() => {
    arrayLink.map((item, index) => {
        if (item === location.pathname) {
            updatePageName({
                pageName: arrayPages[index],
                index: index
            })
        } 
        return index;
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getAvatar());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [localStorage.getItem('token')])

  useEffect(() => {
     if (localStorage.getItem('token')) {
      request.get('/Settings/', {
        params: {UserId: localStorage.getItem('token') }
      })
      .then((response) => dispatch(loadUserSettings(response.data)))
     }
  }, [dispatch])

  const handleLoginPage = () => {
    navigate('/')
  }

  const handleRegisterPage = () => {
      navigate('/register')
  }
  
  return (
    localStorage.getItem('token') ? (
    <>
      <header id="Head" className='header'>
        <img className='header__logo' alt='logo' src={logo} style={{cursor: 'pointer'}} onClick={() => navigate('/', {require: true})}/>
        <ul className="header__menu">
          {
            windowScreen > 576 ? 
            <button ref={ref} className='btn-menu' onClick={() => setMenuActiv(!menuActiv)}>
              <img className='user-avatar' src={avaUrl} alt=''/>
            </button> :
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <div ref={refCloseSelectPage} className='pagePicker' onClick={() => setMenuPage(!menuPage)}>
                <span className='pagePicker__span'>
                  {arraySvg[pageName.index]}
                  <p>{pageName.pageName}</p>
                  <ArrowDown className={menuPage ? 'rotated-arrow' : 'unrotated-arrow'}/>
                </span>
              </div>
              {
                menuPage ? 
                <DDselectPage arrayPages={arrayPages}
                  arrayLink={arrayLink}
                  currentLocation = {location.pathname} 
                  updatePageName={updatePageName}/> : null
              }
              <button ref={ref} className='btn-menu__mobile' onClick={() => setMenuActiv(!menuActiv)}>
                <BurgerMenu></BurgerMenu>
              </button>
            </div>
          }
          {
            windowScreen > 576 ? 
            <DDMenu updateModalState={updateModalState} 
                active={menuActiv} 
                avatar={avaUrl} 
                setActive={setMenuActiv}
                updateTitleModal={updateTitleModal}
                updateDescriptionModal={updateDescriptionModal}
                updateLogoModal={updateLogoModal}
                updateButtonsModal={updateButtonsModal}
            /> :
            <OffcanvasMobile updateModalState={updateModalState} 
                active={menuActiv}
                setActive={setMenuActiv} 
                avatar={avaUrl}
                updateTitleModal={updateTitleModal}
                updateDescriptionModal={updateDescriptionModal}
                updateLogoModal={updateLogoModal}
                updateButtonsModal={updateButtonsModal} 
            />
          }
        </ul>
      </header>
      <body>
        
          {
            modalShow &&
                <ModalWindow updateModalState={updateModalState} 
                    modalShow={modalShow} 
                    title={titleModal}
                    disabledCloseBtn={isDisabledCloseBtn}
                    disabledOutsideClose = {isDisabledOutsideClose} 
                    description={descriptionModal} 
                    logo={logoModal} 
                    buttons={buttonsModal} />
          }
      </body>
      <div>
          <Routes>
            <Route path="/" element={<TodayTask  updateModalState={updateModalState}
                                                  updateTitleModal={updateTitleModal}
                                                  updateDescriptionModal={updateDescriptionModal}
                                                  updateButtonsModal={updateButtonsModal}
                                                  updateIsDisabledModal={updateIsDisabledModal}
                                                  updateIsDisabledOutsideClose={updateIsDisabledOutsideClose} 
                                                  setIcon={setEditTaskModalIcon}/>} />
            <Route path="/:id/modal=:bool" element={<><TodayTask setIcon={setEditTaskModalIcon}/><EditModalTask modalTitle={'Мой день'} editTaskModalIcon={editTaskModalIcon}/></>} />
            <Route path="/login/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/viewlist" element={<IncomingTodosPage setIcon={setEditTaskModalIcon}/>} />
            <Route path="/viewlist/:id/modal=:bool" element={<><IncomingTodosPage setIcon={setEditTaskModalIcon}/><EditModalTask modalTitle={'Входящие задачи'} editTaskModalIcon={editTaskModalIcon}/></>} />
            <Route path="/completed" element={<CompletedTodosPage setIcon={setEditTaskModalIcon}/>} />
            <Route path="/completed/:id/modal=:bool" element={<><CompletedTodosPage setIcon={setEditTaskModalIcon}/><EditModalTask disabled={true} modalTitle={'Выполненные'} editTaskModalIcon={editTaskModalIcon}/> </>} />
            <Route path="/calendar" element={<CalendarPage setIcon={setEditTaskModalIcon}/>} />
            <Route path="/calendar/:id/modal=:bool" element={<><CalendarPage setIcon={setEditTaskModalIcon}/><EditModalTask modalTitle={'Календарь'} editTaskModalIcon={editTaskModalIcon}/> </>} />
            <Route path="/removed_todo" element={<DeletedTodosPage setIcon={setEditTaskModalIcon}/>} />
            <Route path="/removed_todo/:id/modal=:bool" element={<><DeletedTodosPage setIcon={setEditTaskModalIcon}/><EditModalTask disabled={true} modalTitle={'Корзина'} editTaskModalIcon={editTaskModalIcon}/></>} />
            <Route path="/tags" element={<LabelPage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path='/profile' element={<Settings />} />
            <Route path='/test' element={<Settings />} />
            <Route path='/statistics' element={<Settings />} />
            <Route path='/statistics/:id/modal=:bool' element={<><Settings /><EditModalTask disabled={true} modalTitle={'Выполненные'} editTaskModalIcon={editTaskModalIcon}/></>} />
            <Route path="/*" element={<NotFoundPage />} />
            <Route path="/privacy" element={<Privacy />}/>
            <Route path='/register_success' element={<SuccessPage title='Вы успешно зарегистрировались' typeIcon={1}/>} />
          </Routes>
      </div>  
    </>
  ) : (
    <>  
      <header className='header'>
        <img className='header__logo-nologin' alt='logo' src={logo} style={{cursor: 'pointer'}} onClick={() => navigate('/', {require: true})}/>
        {window.screen.width > 576 ?
        <div className='header__buttons' style={isDisabledHeaderBtns ? {display:'none'} : {display:'flex'}}>
          <Button title='Регистрация'  stylesButton={stylesButtonReg} stylesTitle={stylesTitleReg} onClickFunc={handleRegisterPage}/>
          <Button title='Войти' stylesButton={stylesButtonLogin} stylesTitle={stylesTitleLogin} onClickFunc={handleLoginPage}/>
        </div> : null}
      </header>
      <Routes>
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login/" element={<LoginPage />} />   
        <Route path="/" element={<LoginPage />} />   
        <Route path="/forgot_password" element={<ForgotPassword />} />  
        <Route path="/ResetPassword/" element={<ResetPassword />} />
        <Route path="/privacy" element={<Privacy />}/>  
        <Route path="/*" element={<NotFoundPage setIsDisabledHeaderBtns={setIsDisabledHeaderBtns}/>} />
      </Routes>
    </>
  )
  );
}

export default App;

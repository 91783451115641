import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { removeTodo } from "../asyncActions/removeTodos";
import { ReactComponent as CalendarIcon } from "./static/calendar.svg";
import { ReactComponent as DublicateIcon } from "./static/dyblicateIcon.svg";
import { ReactComponent as RemoveIcon } from "./static/removeIcon.svg";
import './ContextMenuTask.css';
import '../DropDownMenu/SelectTaskMenu/index.css';
import { editTodo } from "../asyncActions/editTodos";
import InpuTimeToTask from "../UIcomponents/InputTimeToTask/InputTimeToTask";
import { copyTodo } from "../asyncActions/copyTodo";
import DDSelectDate from "../DropDownMenu/SelectDateCalendar/DDSelectDate";
import { changeOnContextState } from "../store/contextMenuTaskReducer";
import { useChangeFromContextMenu } from "../customHooks/useChangeFromContextMenu";
import { addTodo } from "../asyncActions/addTodos";
import { getTodos } from "../asyncActions/getTodosToday";
import { getTimeAllTodos } from "../asyncActions/getTimeAllTodos";
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";


const ContextMenuTask = ({contextData, contextPosition, task, scrolledY, dateFromCalendarPage, closeContext, onContextStateToTask, fromToday, fromRemoved, dyblicateTaskToday}) => {
    const dispatch = useDispatch();
    const dateToday = new Date();
    const url = 'https://api-demo.timetask.ru/TaskUpdateHub'
    const [taskData, setTaskData] = useState(task);
    const [currentDate] = useState(taskData.date);
    const [connection, setConnection] = useState(null);
    const [hoursToTask, setHoursToTask] = useState(task ? task.hours : '');
    const [minutesToTask, setMinutesToTask] = useState(task ? task.minutes : '');
    const [isActiveCalendar, setIsActiveCalendar] = useState(false);
    const [isActiveTomorrow, setIsActiveTomorrow] = useState(false);
    const [isActiveNoDate, setIsActiveNoDate] = useState(false);
    const [currentPriority, setCurrentPriority] = useState(task ? task.priority : null);
    const refChangeToClose = useRef();
    
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight + scrolledY;
    const menuAxisX = 239;
    const menuAxisY = 331;
    
    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(url)
            .withAutomaticReconnect()
            .build()
            setConnection(newConnection)
        }, []);
        
        useEffect(() => {
            if (connection) {
                connection.start()
                .then(() => {
                    connection.on("Receive", (userId) => {
                        if (userId === localStorage.getItem('token')) {
                            dispatch(getTodos())
                            dispatch(getTimeAllTodos());
                            dispatch(getCountTime());
                        }
                    });
                })
                .catch(err => console.log('Error while establishing connection: ', err));
            }
        }, [connection, dispatch])
        
    const updateTaskList = () => {
        connection.invoke("Send", localStorage.getItem('token'))
        .catch(err => console.error(err));
    }
    
    useChangeFromContextMenu(refChangeToClose, () => dispatch(changeOnContextState({ contextIsOpen: false, contextTaskId: contextData.taskId})), () => dispatch(editTodo({...taskData, updatePageCalendar: dateFromCalendarPage, pageSize: contextData.pageIndex * 10,  pageIndex: 1})), updateTaskList, () => setIsActiveCalendar(false), isActiveCalendar, onContextStateToTask, task, taskData)
    
    const calculatePositionX = () => {
        if ((windowWidth - contextPosition.x_axis) < menuAxisX) {
            return `${windowWidth - (menuAxisX)}px`
        } else {
            return `${contextPosition.x_axis}px`
        }
        
    }

    const calculatePositionY = () => {
        if ((windowHeight - contextPosition.y_axis) < menuAxisY) {
            return `${windowHeight - (menuAxisY) }px`
        } else {
            return `${contextPosition.y_axis}px`
        }
    }

    const handleChangePriority = (value) => {
        setCurrentPriority(value)
        setTaskData({
            ...taskData,
            priority: value
        })
    }
        
    const moveTomorrow = () => {
        if(isActiveTomorrow) {
            setIsActiveTomorrow(!isActiveTomorrow)
            dateToday.setDate(dateToday.getDate())
        } else {
            setIsActiveTomorrow(!isActiveTomorrow)
            dateToday.setDate(dateToday.getDate() + 1)
        }
        setTaskData({
            ...taskData,
            isOpened: false,
            date: dateToday.toLocaleDateString()
            })
    }

    const moveToday = () => {
        if (isActiveTomorrow) {
            setIsActiveTomorrow(!isActiveTomorrow)
            dateToday.setDate(taskData.date)
        } else {
            setIsActiveTomorrow(!isActiveTomorrow)
            dateToday.setDate(dateToday.getDate())
        }
        setTaskData({
            ...taskData,
            isOpened: false,
            date: dateToday.toLocaleDateString()
            })
    }

    const handleUpdateDateFromCalendar = (payload) => {
        setTaskData({
            ...taskData,
            isOpened: false,
            date: payload.date
        })
    }

    const noData = () => {
        if (isActiveNoDate) {
            setIsActiveNoDate(!isActiveNoDate)
            dateToday.setDate(dateToday.getDate())
            setTaskData({
                ...taskData,
                isOpened: false,
                date: dateToday.toLocaleDateString()
                })
            } else {
                setIsActiveNoDate(!isActiveNoDate)
                setTaskData({
                    ...taskData,
                    isOpened: false,
                    date: ''
                    })
            }
    }

    const handleUpdateHours = (payload) => {
        setHoursToTask(payload.timeToTaskHours)
    }

    const handleUpdateMinutes = (payload) => {
        setMinutesToTask(payload.timeToTaskMinutes)
    }

    const handleFocusDrop = (hoursToTask, minutesToTask) => {
        setTaskData({
            ...taskData,
            hours: hoursToTask === '' ? 0 : hoursToTask,
            minutes: minutesToTask === '' ? 0 : minutesToTask
        })
    }

    const handleCopyTask = () => {
        dispatch(addTodo({
            ...taskData,
            title: `Копия - ${taskData.title}`,
            date: dateToday.toLocaleDateString()
        }))
    }
    return (
        <nav className="contextMenu"
            style={{position: 'absolute', 
                    top: calculatePositionY(), 
                    left: calculatePositionX()}}
            onClick={(e) => e.stopPropagation()}
            ref={refChangeToClose}>
            <ul className="contextMenu__first-list">
                <li className="contextMenu__first-item">
                    <div>
                        <DDSelectDate active={isActiveCalendar}
                            calendarPosition={windowWidth > 576 ? {top: '-18px', left: '50px'} : {top: '50%', left: '50%', transform: 'translate(-72%, 0%'}}
                            setActive={setIsActiveCalendar}
                            updateDataDate={handleUpdateDateFromCalendar} 
                            typeOfCalendar={1}/>
                    </div>    
                    <p className="contextMenu__first-item__description">Срок выполнения</p>
                    <div className="contextMenu__first-item__btns-block">
                        <div className="btns-block__calendar-icon" style={taskData.date !== currentDate ? {fill: '#FFF', backgroundColor: '#5932EA'} : null} onClick={() => setIsActiveCalendar(!isActiveCalendar)}>
                            <CalendarIcon></CalendarIcon>
                        </div>
                        {
                            fromToday ? 
                                <div className="btns-block__tomorrow" 
                                style={isActiveTomorrow ? {fontWeight: 700} : null}
                                onClick={() => moveTomorrow() & setIsActiveNoDate(false)}>
                                    <p>Завтра</p>
                                </div> :
                                <div className="btns-block__tomorrow" 
                                    style={isActiveTomorrow ? {fontWeight: 700} : null}
                                    onClick={() => moveToday() & setIsActiveNoDate(false)}>
                                    <p>Сегодня</p>
                                </div>
                        }
                        <span className="btns-block__separator"/>
                        <div className="btns-block__no-date"
                            style={isActiveNoDate ? {fontWeight: 700} : null} 
                            onClick={() => noData() & setIsActiveTomorrow(false)}>
                            <p>Без срока</p>
                        </div>
                    </div>
                        
                </li>
                <li className="contextMenu__first-item">
                    <p className="contextMenu__first-item__description">Приоритет</p>
                    <div className="contextMenu__first-item__priority-container">
                        <ul className="contextMenu__first__priority-list" >
                            <li>
                                <span id='0' 
                                    className={currentPriority === 0 ? "contextMenu__first__priority-list__item current-priority item-1" : 
                                    "contextMenu__first__priority-list__item item-1"} 
                                        onClick={(e) => handleChangePriority(0)}
                                        >
                                    <p className="priority-list__item-description">Высокий</p>
                                </span>
                            </li>
                            <li>
                                <span id='1' 
                                    className={currentPriority === 1 ? "contextMenu__first__priority-list__item current-priority item-2" : 
                                    "contextMenu__first__priority-list__item item-2"}
                                    onClick={() => handleChangePriority(1)}
                                    >
                                    <p className="priority-list__item-description">Средний</p>
                                </span>
                            </li>
                            <li>
                                <span id='2' 
                                    className={currentPriority === 2 ? "contextMenu__first__priority-list__item current-priority item-3" : 
                                    "contextMenu__first__priority-list__item item-3"} 
                                    onClick={() => handleChangePriority(2)}
                                    >
                                    <p className="priority-list__item-description">Низкий</p>
                                </span>
                            </li>
                            <li>
                                <span id='3' 
                                    className={currentPriority === 3 ? "contextMenu__first__priority-list__item current-priority item-4" : 
                                    "contextMenu__first__priority-list__item item-4"} 
                                    onClick={() => handleChangePriority(3)}
                                    >
                                    <p className="priority-list__item-description">Без приоритета</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className="contextMenu__first-item">
                    <p style={{marginBottom: '20px'}} className="contextMenu__first-item__description">Время на задачу</p>
                    <InpuTimeToTask updateDataTimeToTaskHours={handleUpdateHours} 
                        updateDataTimeToTaskMinutes={handleUpdateMinutes} 
                        timeToTaskHours={hoursToTask} 
                        timeToTaskMinutes={minutesToTask} 
                        submitChangeContext={handleFocusDrop}/>
                </li>
                <ul className="contextMenu__second-list">
                    <li className="contextMenu__second-item"
                        style={fromRemoved && {cursor:'default', pointerEvents: 'none', color: '#A4A8B1'}}
                        onClick={() => (dyblicateTaskToday ? handleCopyTask() : dispatch(copyTodo({id: contextData.taskId, dateFromCalendarPage: dateFromCalendarPage}))) & closeContext(contextData.taskId) & updateTaskList()}>
                            <DublicateIcon style={fromRemoved && {fill: '#A4A8B1'}} className="dublicateIcon" />
                            <p className="contextMenu__item__description">Дублировать задачу</p>
                    </li>
                    <li className="contextMenu__second-item"
                        style={fromRemoved && {cursor:'default', pointerEvents: 'none', color: '#A4A8B1'}}
                        onClick={() => dispatch(removeTodo({id: contextData.taskId, dateFromCalendarPage: dateFromCalendarPage})) & closeContext(contextData.taskId) & updateTaskList()}>
                            <RemoveIcon style={fromRemoved && {fill: '#A4A8B1'}} className="removeIcon" />
                            <p className="contextMenu__item__description">Удалить</p>
                    </li>
                </ul>
            </ul>
            
        </nav>
    )
}

export default ContextMenuTask;
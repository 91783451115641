import React, {useEffect, useRef} from "react";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { activateTimer } from "../store/RunTimeTaskResults/RunTimeResults";
import { addRunTime } from "../asyncActions/RunTimeActions/addRunTime";
import "./index.css";
import { Placeholder } from "react-bootstrap";
import { editTodo } from "../asyncActions/editTodos";
import { timerPush } from "../asyncActions/TimerActions/timerPush";
import request from "../Requests/requests";

interface StopWatchProps {
    task: {
        date: Date,
        description: string,
        hours: number,
        id: number,
        isCompleted: boolean,
        isDelete: boolean,
        isOpened: boolean,
        minutes: number,
        priority: number,
        runTime: number,
        tags: string[]
        time: string,
        timerIsActive: boolean
        timerStart: number
        timerTime: number,
        title: string,
        transferCount: number,
        userId: string
    },
    fromToday: boolean,
    TodoListView: boolean,
    loading: boolean,
    disabled: boolean,
    modalShow: boolean,
    runTime: number,
    timeTracking: boolean,
    dateCurrent: Date,
    completedTodoView: boolean,
    updateIsOpened: Function,
    updateRunTime: Function,
    updateDataHours: Function,
    updateDataMinutes: Function,
    updateTaskFunc: Function,
    fromTodo: any,
    settings: object
}

export function autoClosePreviousTimer(activeTaskId: number, currentTaskId: number) {        
    return new Promise<void>(async function (resolve, reject){
        if (activeTaskId !== currentTaskId) {
            const response = await request.get('/Task/Task', {params: {Id: activeTaskId}})
            if (response.status === 200) {
                resolve(response.data.runTime)
            } else {
                reject('Error fetching')    
            }
        } 
    })
}

export default function TaskStopWatch({
    task,
    fromToday,
    TodoListView,
    loading,
    disabled,
    modalShow,
    timeTracking,
    fromTodo,
    completedTodoView,
    updateIsOpened,
    updateRunTime,
    updateDataHours,
    updateDataMinutes,
    runTime,
    updateTaskFunc}: StopWatchProps) {
    const dispatch = useDispatch();
    const url = (process.env.REACT_APP_API_BASEURL_HUB ?? 'https://api-demo.timetask.ru/') + 'TimerHub';    
    const stopWatchesData = useSelector((state: any) => state.stopWatchesData.stopWatchesData);
    const actionArray = stopWatchesData.find((actionArr: {taskId: number}) => actionArr.taskId === task.id);
    const [isFocusScheduled, setIsFocusScheduled] = React.useState<Boolean>(false);
    const [isFocusElapsed, setIsFocusElapsed] = React.useState<Boolean>(false);
    const [connection, setConnection] = React.useState<any>(null);
    const activeTimer = stopWatchesData.find((timer: any) => timer.isTimerActive === true)
    const DeclaredTime = (task.hours * 3600) + (task.minutes * 60);
    const isHours = Math.floor(task.runTime / 3600);
    const isMinutes = Math.floor((task.runTime / 60) - (Math.floor(task.runTime / 3600) * 60));
    const isSecconds = Math.floor(task.runTime % 60);
    const stopWatchRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const timeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .build()
        setConnection(newConnection)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        }, []);
        
    useEffect(() => {
        if (modalShow && connection) {
            autoStart()
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [modalShow, connection])
    
    useEffect(() => {
        if (!modalShow) {
            clearTimeout(timeOutRef.current as NodeJS.Timeout)
        }
    }, [modalShow])

    function autoStart() {        
        if ((timeTracking)) {
            if (actionArray?.isTimerActive || !task.isOpened) {
                return
            } else {
                const startTime = new Date().getTime();
                if (((task.isCompleted  || task.isDelete) !== true && task.isOpened) || !disabled) {
                    autoClosePreviousTimer(activeTimer?.taskId, task.id)
                        .then((runTime: any) => {
                            dispatch<any>(addRunTime({id: activeTimer?.taskId, seconds: Math.floor((startTime - activeTimer?.startTime) / 1000) + runTime}))
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                    dispatch<any>(timerPush({taskId: task.id, timerIsActive: true, startTime: startTime}))
                    timeOutRef.current = setTimeout(() => {
                        sendTimer(startTime)
                    }, 1000);
                } else {
                    return
                }
            }
        }
    }

    function StartStopTimer(start: any): void {
        if(!task.isOpened) {
            dispatch<any>(editTodo({
                ...task,
                isOpened: true
            }))
        }
        autoClosePreviousTimer(activeTimer?.taskId, task.id).then((runTime: any) => {
            dispatch<any>(addRunTime({id: activeTimer?.taskId, seconds: Math.floor((start - activeTimer?.startTime) / 1000) + runTime}))
        })
        dispatch<any>(timerPush({taskId: task.id, timerIsActive: !actionArray?.isTimerActive, startTime: start}))
        sendTimer(start);
        if (actionArray?.isTimerActive) {
            dispatch<any>(addRunTime({id: task.id, seconds: task.runTime}))
            if (modalShow) {
                dispatch<any>(editTodo(task))                     
            }
        }
        updateIsOpened({isOpened: true});
    }
    
    useEffect(() => {
        if (connection) {
            connection.start()
              .then(() => {
                connection.on("Receive", (userId: string, taskId: number, timerStart: number, isActive: boolean, taskUpdate: string) => {
                    if (userId === localStorage.getItem('token')) {
                            dispatch(activateTimer({isTimerActive: isActive, taskId: taskId, startTime: timerStart}));
                            if (modalShow === true ) {
                                setTimeout(() => {
                                        updateTaskFunc()
                                    }, 1000);
                            }
                        }
                    
                    });
              })
              .catch((err: any) => console.log('Error while establishing connection: ', err));
          }
          /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [connection])
    
    const sendTimer = (startTime: any) => {
        connection.invoke("Send", localStorage.getItem('token'), task.id, startTime, !actionArray?.isTimerActive)
          .catch((err: any) => console.error(err));
    }
    
    useEffect(() => {        
        const startTime = typeof actionArray !== 'undefined' ? actionArray.startTime !== null ? actionArray.startTime : new Date().getTime() : new Date().getTime();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        stopWatchRef.current = setInterval(() => {
            if (actionArray?.isTimerActive) {
                const curTime = new Date().getTime();
                // console.log('Current: ', curTime, ';', 'Start: ', startTime, ';', 'Props: ', task.runTime, 'Count: ', ((curTime - startTime) / 1000));
                const delta = curTime - startTime;
                let sec = Math.floor(delta / 1000) + task.runTime
                updateRunTime({
                    seconds: sec
                })
            } else {
                clearInterval(stopWatchRef.current as NodeJS.Timeout)
            }       
        }, 1000)
        return () => clearInterval(stopWatchRef.current as NodeJS.Timeout)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [actionArray])

    function normalizeTime(e: any) {
        if (e.target.id === 'scheduledRunTime__hours') {
            if (e.target.value > 23) {
                updateDataHours({hours: 23})
            } else if (e.target.value < 0) {
                updateDataHours({hours: 0})
            } else {
                updateDataHours({hours: e.target.value})
            }
        } else if (e.target.id === 'scheduledRunTime__minutes') {
            if (e.target.value > 59) {
                updateDataMinutes({minutes: 59})
            } else if (e.target.value < 0) {
                updateDataMinutes({minutes: 0})
            } else {
                updateDataMinutes({minutes: e.target.value})
            }
        } else {
            e.preventDefault()
        }   
    }

    function checkWords(e: any) {
        if ("0123456789".indexOf(e.key) !== -1) {
            normalizeTime(e)
        } else {
            e.preventDefault()
        }
    }

    const changeHours = (e: any) => {
        if (e.target.value > 99) {
            updateRunTime({
                seconds: ((Number(99) * 3600) + (isMinutes * 60) + isSecconds)
            })
        } else {
            updateRunTime({
                seconds: ((Number(e.target.value) * 3600) + (isMinutes * 60) + isSecconds)
            })
        }
    }

    const changeMinutes = (e: any) => {
        if (e.target.value > 59) {
            updateRunTime({
                seconds: ((isHours * 3600) + (Number(59) * 60) + isSecconds)
            })
        } else {
            updateRunTime({
                seconds: ((isHours * 3600) + (Number(e.target.value) * 60) + isSecconds)
            })
        }
    }

    const changeSecconds = (e: any) => {
        if (e.target.value > 59) {
            updateRunTime({
                seconds: ((isHours * 3600) + (isMinutes * 60) + Number(59))
            })
        } else {
            updateRunTime({
                seconds: ((isHours * 3600) + (isMinutes * 60) + Number(e.target.value))
            })
        }
    }

    const onBlurUpdate = () => {
        setIsFocusElapsed(false)
        dispatch<any>(addRunTime({id: task.id, seconds: task.runTime}))
    }
    
    return (
        <>
        <div className="runTime-container">
            <div className="timer-container" 
                style={completedTodoView ? {flexDirection: "row"} : TodoListView ? 
                    {flexDirection: "row-reverse", alignItems: "flex-end"} : 
                    fromToday ? {gap: '0px'} : {flexDirection: "row"}}>
                {fromTodo ? null : 
                <button className={actionArray?.isTimerActive ? 
                            "pauseTime-btn" 
                            : 
                            "startTime-btn"}
                    style={completedTodoView ? {display: "none"} : {display: "block"}} 
                    onClick={() => StartStopTimer(new Date().getTime())}
                    disabled={disabled}
                    /> }
                        <div className="timer-info-container" style={TodoListView ?
                            {marginLeft: '0px'} :
                            {marginLeft: '4px'}}>
                                {
                                    loading ? 
                                        <div style={{width: '100px'}}>
                                            <Placeholder animation="wave">
                                                <Placeholder xs={12} bg="light"></Placeholder>
                                            </Placeholder>
                                        </div> :
                                    <>
                                        {
                                TodoListView ? 
                                <div className="elapsedRunTime__container__todoview">
                                    <span className="elapsedRunTime__span">
                                        <p className="elapsedRunTime__info" 
                                            style={disabled ? {color :'#A4A8B1'} : task.runTime > DeclaredTime ? 
                                                {color: '#F85640'} : 
                                                {color: '#232360'}}>
                                            {Math.floor(task.runTime / 60) === 0 ? 
                                            '00' : 
                                            Math.floor(task.runTime / 60) <= 9 ? 
                                                '0' + Math.floor(task.runTime / 60) : 
                                                Math.floor(task.runTime / 60)}
                                        </p>
                                        <p className="elapsedRunTime__description" 
                                            style={disabled ? {color :'#A4A8B1'} : task.runTime > DeclaredTime ? 
                                                {color: '#F85640'} : 
                                                {color: '#232360'}}>
                                            мин
                                        </p>
                                    </span>
                                </div>
                                :
                                <div className="elapsedRunTime__container" 
                                    style={isFocusElapsed ? {border: disabled ? '1px solid #5932EA' : '1px solid #E5E7EB', borderRadius: '4px', padding: '0px 6px'} : {}}
                                    onFocus={() => dispatch(activateTimer({isTimerActive: false, taskId: task.id, startTime: new Date().getTime()})) && setIsFocusElapsed(true)}
                                    onBlurCapture={() => onBlurUpdate()}>
                                    <input type="text"
                                        maxLength={4}
                                        style={disabled ? {color :'#A4A8B1'} : {}}
                                        className={task.runTime > DeclaredTime ? 
                                                "scheduledRunTime__hours-over" 
                                                : 
                                                "scheduledRunTime__hours"} 
                                        value={Math.floor(task.runTime / 3600) <= 9 ? 
                                            '0' + Math.floor(task.runTime / 3600) : 
                                            Math.floor(task.runTime / 3600)}
                                        // onClick={() => dispatch(activateTimer({isTimerActive: false, taskId: task.id, startTime: new Date().getTime()}))}
                                        onChange={(e) => changeHours(e)}
                                        disabled={disabled}/>
                                    <span className={task.runTime > DeclaredTime ? "split-over" : "split" }
                                            style={disabled ? {color :'#A4A8B1'} : {}}>:</span>
                                    <input type="text"
                                            maxLength={4}
                                            style={disabled ? {color :'#A4A8B1'} : {}} 
                                            className={task.runTime > DeclaredTime ? 
                                                    "scheduledRunTime__minutes-over" 
                                                    : 
                                                    "scheduledRunTime__minutes"} 
                                            value={Math.floor((task.runTime / 60) - (Math.floor(task.runTime / 3600) * 60)) <= 9 ? 
                                                '0' + Math.floor((task.runTime / 60) - (Math.floor(task.runTime / 3600) * 60)) : 
                                                Math.floor((task.runTime / 60) - (Math.floor(task.runTime / 3600) * 60))}
                                            // onClick={() => dispatch(activateTimer({isTimerActive: false, taskId: task.id, startTime: new Date().getTime()}))}
                                            onChange={(e) => changeMinutes(e)}
                                            disabled={disabled}/>
                                    <span className={task.runTime > DeclaredTime ? "split-over" : "split" }
                                            style={disabled ? {color :'#A4A8B1'} : {}}>:</span>
                                    <input type="text"
                                            maxLength={4}
                                            style={disabled ? {color :'#A4A8B1'} : {}} 
                                            className={task.runTime > DeclaredTime ? 
                                                "scheduledRunTime__secconds-over" 
                                                : 
                                                "scheduledRunTime__secconds"} 
                                            value={Math.floor(task.runTime % 60) <= 9 ? 
                                                '0' + Math.floor(task.runTime % 60) :
                                                Math.floor(task.runTime % 60)}
                                            // onClick={() => dispatch(activateTimer({isTimerActive: false, taskId: task.id, startTime: new Date().getTime()}))}
                                            onChange={(e) => changeSecconds(e)}
                                            disabled={disabled}/>
                                </div>
                            }    
                            <span className="timer-info-container__separator"
                                style={disabled ? {color :'#A4A8B1'} : {}}>/</span>
                            {
                                TodoListView ?
                                <div className="scheduledRunTime__container__todoview">
                                    <span className="scheduledRunTime__span">
                                        <p className="scheduledRunTime__info"
                                            style={disabled ? {color :'#A4A8B1'} : {}}>
                                            {((task.hours * 60) + task.minutes) === 0 ? 
                                            '00' : 
                                            ((task.hours * 60) + task.minutes) <= 9 ? 
                                                '0' + ((task.hours * 60) + task.minutes) : 
                                                ((task.hours * 60) + task.minutes)}
                                        </p>
                                        <p className="scheduledRunTime__description"
                                            style={disabled ? {color :'#A4A8B1'} : {}}>
                                            мин
                                        </p>
                                    </span>
                                </div>
                                :
                                <div className="scheduledRunTime__container"
                                    style={isFocusScheduled ? {border:  disabled ? '1px solid #5932EA' : '1px solid #E5E7EB', borderRadius: '4px', padding: '0px 6px'}: {}}
                                    onFocus={() => setIsFocusScheduled(true)}
                                    onBlurCapture={() => setIsFocusScheduled(false)}>
                                    <input type="text" 
                                            maxLength={4}
                                            style={disabled ? {color :'#A4A8B1'} : {}}
                                            autoComplete="off" 
                                            id='scheduledRunTime__hours' 
                                            className="scheduledRunTime__hours" 
                                            value={`${task.hours}`.padStart(2, '0').length > 2 ? 
                                                `${task.hours}`.slice(1) : 
                                                `${task.hours}`.padStart(2, '0')} 
                                            onKeyPress={e => checkWords(e)}
                                            onChange={(e) => normalizeTime(e)}
                                            disabled={disabled}/>
                                    <span style={disabled ? {color :'#A4A8B1'} : {}}>:</span>
                                    <input type="text" 
                                            maxLength={4}
                                            style={disabled ? {color :'#A4A8B1'} : {}}
                                            autoComplete="off" 
                                            id='scheduledRunTime__minutes' 
                                            className="scheduledRunTime__minutes" 
                                            value={`${task.minutes}`.padStart(2, '0').length > 2 ?
                                                `${task.minutes}`.slice(1) :
                                                `${task.minutes}`.padStart(2, '0')} 
                                            onKeyPress={e => checkWords(e)}
                                            onChange={(e) => normalizeTime(e)}
                                            disabled={disabled}/>
                                </div>

                            }
                                    </>
                                }
                            
                        </div>
                
            </div>
            <div className="runTime-container__percentTime" style={{backgroundColor: task.runTime > DeclaredTime ? '#F85640' : '#E1E4ED'}}>
                <div className="runTime-container__percentTime-complete" 
                    style={{width: isNaN((100 * task.runTime) / ((task.hours * 3600) + (task.minutes * 60))) ? 
                    '0%' :
                    (((100 * task.runTime) / ((task.hours * 3600) + (task.minutes * 60))) + '%'), 
                    backgroundColor: disabled ? '#A4A8B1' : task.runTime > DeclaredTime ? '#F85640' : '#5932EA'}}/>
            </div>
        </div>
        </>
    )
}
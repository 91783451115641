import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { TaskTableState } from "./store/TaskTableSectionStore";
import './TaskTableSection.css'
import FilterTableHead from "./component/FilterTableHead/FilterTableHead";
import { getTaskTable, loadTaskTable } from "../../asyncActions/TaskTableService/taskTableService";
import TagsView from "./component/TagsView/TagsView";
import PriorityView from "./component/PriorityView/PriorityView";
import Loader from "../../Loader/Lodaer";
import leftIcon from "./static/left.svg"
import rightIcon from "./static/right.svg"
import eyeIcon from "./static/eye.svg"
import { useLocation, useNavigate } from "react-router-dom";
import Donat from "./component/Donat/Donat";

export interface TaskTableSectionProps {
  dateStart: string,
  dateEnd: string
}

const TaskTableSection = ({ dateStart, dateEnd }: TaskTableSectionProps) => {
  const dispatch = useDispatch();
  const taskTableState: TaskTableState = useSelector((state: any) => state.taskTableState);
  const [activeCl, setActiveCl] = useState(1);
  const [filter, setFilter] = useState({
    orderBy: '',
    pageIndex: 1,
    pageSize: 7,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch<any>(getTaskTable(dateStart, dateEnd, filter.orderBy, 1, filter.pageSize))
      .then(() => {
        if (taskTableState.error) {
          console.log(taskTableState.error);
        }
      });
    setFilter({ ...filter, pageIndex: 2 });
    // eslint-disable-next-line
  }, [dateStart, dateEnd, filter.orderBy]);

  const loadData = () => {
    dispatch<any>(loadTaskTable(dateStart, dateEnd, filter.orderBy, filter.pageIndex, filter.pageSize))
      .then(() => {
        if (taskTableState.error) {
          console.log(taskTableState.error);
        }
      });
  }

  const getMonthStr = (month: number) => {
    const monthMap = new Map([
      [1, 'янв'],
      [2, 'фев'],
      [3, 'мар'],
      [4, 'апр'],
      [5, 'май'],
      [6, 'июн'],
      [7, 'июл'],
      [8, 'авг'],
      [9, 'сен'],
      [10, 'окт'],
      [11, 'ноя'],
      [12, 'дек']
    ]);

    return monthMap.get(month);
  }


  const fomatDateString = (date: string) => {
    let result = '';
    result += date.split('.')[0][0] === '0' ? date.split('.')[0][1] : date.split('.')[0];
    result += ' ' + getMonthStr(Number(date.split('.')[1]));
    let curDate = new Date().getFullYear().toString();
    if (curDate !== date.split('.')[2]) {
      result += ' ' + date.split('.')[2];
    }

    return result;
  }

  const swipeLeft = () => {
    if (activeCl > 1) {
      setActiveCl(activeCl - 1)
    }
  }

  const handleOpenTask = (value: number) => {
    navigate(`${location.pathname}/${value}/modal=${true}`, {state: {path: location.pathname, dateRange: {start: dateStart, end: dateEnd}}})
  }

  const swipeRight = () => {
    if (activeCl < 5) {
      setActiveCl(activeCl + 1)
    }
  }


  return (
    <div className="task_table_section">
      {taskTableState.isLoading ? (
        <div>
          <table>
            <thead className="table_head">
              <tr className="table_h_tr">
                <th className="table_th th_left">№</th>
                <th className="table_th">
                  <FilterTableHead
                    value={"Название задачи"}
                    filterName={"name"}
                    orderBy={filter.orderBy}
                    change={(e: string) => setFilter({ ...filter, orderBy: e })}
                  />
                </th>
                <th className={"table_th " + (activeCl === 1 ? "cl_show" : "cl_hidden")}>
                  <p>Метки</p>
                </th>
                <th className={"table_th " + (activeCl === 2 ? "cl_show" : "cl_hidden")}>
                  <FilterTableHead
                    value={"Дата"}
                    filterName={"date"}
                    orderBy={filter.orderBy}
                    change={(e: string) => setFilter({ ...filter, orderBy: e })}
                  />
                </th>
                <th className={"table_th " + (activeCl === 3 ? "cl_show" : "cl_hidden")}>
                  <FilterTableHead
                    value={"Приоритет"}
                    filterName={"priority"}
                    orderBy={filter.orderBy}
                    change={(e: string) => setFilter({ ...filter, orderBy: e })}
                  />
                </th>
                <th className={"" + (activeCl === 4 ? "cl_show" : "cl_hidden")}>
                  <p className="table_th_multi">
                    <p className="time">Время</p>
                    <div className="th_center time_btn">
                      <th>
                        <FilterTableHead
                          value={"Факт"}
                          filterName={"factTime"}
                          orderBy={filter.orderBy}
                          change={(e: string) => setFilter({ ...filter, orderBy: e })}
                        />
                      </th>
                      <p>/</p>
                      <th>
                        <FilterTableHead
                          value={"План"}
                          filterName={"planTime"}
                          orderBy={filter.orderBy}
                          change={(e: string) => setFilter({ ...filter, orderBy: e })}
                        />
                      </th>
                    </div>
                  </p>
                </th>
                <th className={"table_th th_right " + (activeCl === 5 ? "cl_show" : "cl_hidden")}>
                  <FilterTableHead
                    value={"Точность прогноза"}
                    filterName={"forecastAccuracy"}
                    orderBy={filter.orderBy}
                    change={(e: string) => setFilter({ ...filter, orderBy: e })}
                  />
                </th>
                <th className="table_th th_swipe">
                  <img src={leftIcon} className="swipe_btn" onClick={swipeLeft} alt="left" />
                  <img src={rightIcon} className="swipe_btn" onClick={swipeRight} alt="right" />
                </th>
              </tr>
            </thead>
          </table>
          <div className="load_section">
            <Loader />
          </div>
        </div>
      ) : (
        <table>
          <thead className="table_head">
            <tr className="table_h_tr">
              <th className="table_th th_left">№</th>
              <th className="table_th">
                <FilterTableHead
                  value={"Название задачи"}
                  filterName={"name"}
                  orderBy={filter.orderBy}
                  change={(e: string) => setFilter({ ...filter, orderBy: e })}
                />
              </th>
              <th className={"table_th " + (activeCl === 1 ? "cl_show" : "cl_hidden")}>
                <p>Метки</p>
              </th>
              <th className={"table_th " + (activeCl === 2 ? "cl_show" : "cl_hidden")}>
                <FilterTableHead
                  value={"Дата"}
                  filterName={"date"}
                  orderBy={filter.orderBy}
                  change={(e: string) => setFilter({ ...filter, orderBy: e })}
                />
              </th>
              <th className={"table_th " + (activeCl === 3 ? "cl_show" : "cl_hidden")}>
                <FilterTableHead
                  value={"Приоритет"}
                  filterName={"priority"}
                  orderBy={filter.orderBy}
                  change={(e: string) => setFilter({ ...filter, orderBy: e })}
                />
              </th>
              <th className={"" + (activeCl === 4 ? "cl_show" : "cl_hidden")}>
                <p className="table_th_multi">
                  <p className="time">Время</p>
                  <div className="th_center time_btn">
                    <th>
                      <FilterTableHead
                        className="fact_plan_time"
                        value={"Факт"}
                        filterName={"factTime"}
                        orderBy={filter.orderBy}
                        change={(e: string) => setFilter({ ...filter, orderBy: e })}
                      />
                    </th>
                    <p>/</p>
                    <th>
                      <FilterTableHead
                        className="fact_plan_time cl_hidden"
                        value={"План"}
                        filterName={"planTime"}
                        orderBy={filter.orderBy}
                        change={(e: string) => setFilter({ ...filter, orderBy: e })}
                      />
                    </th>
                  </div>
                </p>
              </th>
              <th className={"table_th th_right " + (activeCl === 5 ? "cl_show" : "cl_hidden")}>
                <FilterTableHead
                  value={"Точность прогноза"}
                  filterName={"forecastAccuracy"}
                  orderBy={filter.orderBy}
                  change={(e: string) => setFilter({ ...filter, orderBy: e })}
                />
              </th>
              <th className="table_th th_swipe">
                <p className="swipe_block">
                  <img src={leftIcon} className="swipe_btn" onClick={swipeLeft} alt="left" />
                  <img src={rightIcon} className="swipe_btn" onClick={swipeRight} alt="right" />
                </p>
              </th>
            </tr>
          </thead>
          <tbody className="table_body">
            {taskTableState.data.tasks?.map((x, i: number) => (
              <tr className="table_h_tr table_tr" key={x.id}>
                <td className="table_body_td">{i + 1}</td>
                <td className={window.screen.width > 576 ? "table_body_td item-hover" : "table_body_td"} onClick={(e) => window.screen.width > 576 ? handleOpenTask(x.id) : e.preventDefault()}>{x.name}</td>
                <td className={"table_body_td " + (activeCl === 1 ? "cl_show" : "cl_hidden")}>
                  <TagsView tags={x.tags} />
                </td>
                <td className={"table_body_td " + (activeCl === 2 ? "cl_show" : "cl_hidden")}>{fomatDateString(x.date.split(' ')[0])}</td>
                <td className={"table_body_td " + (activeCl === 3 ? "cl_show" : "cl_hidden")}>
                  <PriorityView id={x.priority} />
                </td>
                <td className={"table_body_td " + (activeCl === 4 ? "cl_show" : "cl_hidden")}>
                  <p className="td_center">
                  <p className={x.factTime > x.planTime ? "time_warn" : "time_norm"}>{x.factTime}</p>
                  <p className={x.factTime > x.planTime ? "time_warn_min" : "time_norm_min"}> мин</p>
                  / <p className="time_norm">{x.planTime}</p>
                  <p className="time_norm_min"> мин</p>
                  </p>
                </td>
                <td className={"table_body_td " + (activeCl === 5 ? "cl_show" : "cl_hidden")}>
                  <p className="donat_block">
                    <Donat percent={x.forecastAccuracy} />
                    <p className="percent">{x.forecastAccuracy}%</p>
                  </p>
                </td>
                <td className="th_swipe">
                  <img className="eye_icon" src={eyeIcon} alt="eye" onClick={() => handleOpenTask(x.id)}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {taskTableState.data.hasNextPage &&
        <div className="load_data">
          <button
            className="load_btn"
            onClick={() => { setFilter({ ...filter, pageIndex: filter.pageIndex + 1 }); loadData() }}>
            Показать ещё
          </button>
        </div>
      }
    </div>
  )
}

export default TaskTableSection;
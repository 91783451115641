import request from "../../../Requests/requests";
import { useState, useEffect } from "react";
import {ReactComponent as ArrowIcon} from "../../../icons/svg/arrow-select.svg";

export default function MainSettings (props) {
    const [checkedScreen, setCheckedScreen] = useState();
    const [checkedSmartDate, setCheckedSmartDate] = useState();
    const [checkedTimeTracking, setCheckedTimeTracking] = useState();
    const [timeZone, setTimeZone] = useState();
    const [currentTZ, setCurrentTZ] = useState();
    
    useEffect(() => {
        request.get('/Settings/', {
            params: {UserId: localStorage.getItem('token') }
        })
        .then(response => {
            const settings = response.data;
            setCheckedScreen(settings.settingsType);
            setCheckedSmartDate(settings.isSmartDateRecognition);
            setCheckedTimeTracking(settings.isTimeTracking);
            setTimeZone(settings.timeZone);
            setCurrentTZ(settings.currentTimeZone);
        })    
    }, [])

    function checkBeforePush(e) {
        props.isDisabled(false)
        props.isChangedFunction(true)
        props.fromClose('mainSettingsPage')
        props.updateDataMainSetting({checkedScreen: checkedScreen,
            checkedSmartDate: e.target.id === 'checkedSmartDate' ? e.target.checked : checkedSmartDate,
            checkedTimeTracking: e.target.id === 'checkedTimeTracking' ? e.target.checked : checkedTimeTracking,
            timeZone: e.target.id === 'priority' ? e.target.value : timeZone,
            currentTZ: currentTZ
        })
    }

    return (
            <div className="main-settings-page__container">
                <form>
                    <ul className="main-settings-page__list">
                        <li className="main-settings-page__item">
                            <div>
                                <div className="main-settings-page__title">Автоматический учёт времени</div>
                                <div className="main-settings-page__toogle">
                                    <label className="switch">
                                    <input type="checkbox" id="checkedTimeTracking" checked={checkedTimeTracking}  onClick={(e) => setCheckedTimeTracking(!checkedTimeTracking) & checkBeforePush(e)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <p>Автоматически начинается учёт времени, когда открыта задача.</p>
                        </li>
                        {/* <li className="main-settings-page__item">
                            <div>
                                <div className="main-settings-page__title">Умное распознавание</div>
                                <div className="main-settings-page__toogle">
                                    <label className="switch">
                                        <input type="checkbox" id="checkedSmartDate" checked={checkedSmartDate} onClick={(e) => setCheckedSmartDate(!checkedSmartDate) & checkBeforePush(e)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <p>При вводе определённых последовательностей атоматически считывается дата, время, метки и .т.д</p>
                        </li> */}
                        {/* <li className="main-settings-page__item">
                            <div>
                                <div className="main-settings-page__title">Время на обед</div>
                                <div className="main-settings-page__toogle">
                                    <label className="switch">
                                    <input type="checkbox" id="checkedTimeTracking" checked={checkedTimeTracking}  onClick={(e) => setCheckedTimeTracking(!checkedTimeTracking) & checkBeforePush(e)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <p>На странице Мой день в дашборде будет сразу учитываться время на обед.</p>
                        </li> */}
                        <li className="main-settings-page__item">
                            <div>
                                <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <div className="main-settings-page__title">Часовой пояс</div>
                                    <p className="main-settings-page__description">Выберите часовой пояс вашего региона.</p>
                                </div>
                                <div className="main-settings-page__toogle">
                                    <div className="input-time-zone">
                                        <select name='priority' id='priority' className="add-timezone input-select" value={timeZone} onChange={e => setTimeZone(e.target.value) & checkBeforePush(e)}>
                                            <option value='-12'>GMT -12</option>
                                            <option value='-11'>GMT -11</option>
                                            <option value='-10'>GMT -10</option>
                                            <option value='-9'>GMT -9</option>
                                            <option value='-8'>GMT -8</option>
                                            <option value='-7'>GMT -7</option>
                                            <option value='-6'>GMT -6</option>
                                            <option value='-5'>GMT -5</option>
                                            <option value='-4'>GMT -4</option>
                                            <option value='-3'>GMT -3</option>
                                            <option value='-2'>GMT -2</option>
                                            <option value='-1'>GMT -1</option>
                                            <option value='0'>GMT</option>
                                            <option value='1'>GMT +1</option>
                                            <option value='2'>GMT +2</option>
                                            <option value='3'>GMT +3</option>
                                            <option value='4'>GMT +4</option>
                                            <option value='5'>GMT +5</option>
                                            <option value='6'>GMT +6</option>
                                            <option value='7'>GMT +7</option>
                                            <option value='8'>GMT +8</option>
                                            <option value='9'>GMT +9</option>
                                            <option value='10'>GMT +10</option>
                                            <option value='11'>GMT +11</option>
                                            <option value='12'>GMT +12</option>
                                        </select>
                                        <div>
                                            <ArrowIcon/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
    )
}
import React, { useRef } from "react";
import './ModalWindow.css';
import {ReactComponent as CloseBtn} from './static/closeModal.svg';
import { useOutsideClick } from "../../customHooks/useOutsideClick";

const ModalWindow = ({title, description, logo, buttons, modalShow, updateModalState, disabledCloseBtn, disabledOutsideClose}) => {
    const refCloseModal = useRef();
    useOutsideClick(refCloseModal, () => updateModalState(false), modalShow);

    return (
        <div id="openModal" className={modalShow ? "modalWindow" : 'hide'}>
            <div className="modalWindow__container" ref={disabledOutsideClose === true ? null : refCloseModal}>
                    <div className="modalWindow__close" style={disabledCloseBtn ? {display: "none"} : null} onClick={() => updateModalState(false)}>
                    <CloseBtn className="modalWindows_close-btn"/>
                    </div>
                {logo && <div className="modalWindow__logo">
                        {logo}
                    </div>}
                <div className="modalWindow__content">
                    <div className="modalWindow__header">
                        <p className="modalWindow__title">{title}</p>
                    </div>
                    <div className="modalWindow__body">    
                        <p className="modalWindow__body-description">
                            {description} 
                        </p>
                    </div>
                </div>
                { buttons &&
                    <div className="modalWindow__btn-group">
                        {buttons}
                    </div>
                }
                    {/* <div className="modalWindow__btn-group">
                        {
                            data.WindowFromProp === 'TagsList' ? 
                            <button className="modalWindow__btn-group__save" onClick={() => setModalShow(false)}>Ок</button>: 
                            <>
                                <button className="modalWindow__btn-group__close" onClick={() => StashChanges()}>Отмена</button>
                                <button className="modalWindow__btn-group__save" onClick={() => SaveChanges()}>Сохранить</button>
                            </>
                        }
                    </div> */}
            </div>
        </div>
    )
}

export default ModalWindow;
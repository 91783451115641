import request from "../Requests/requests";
import { removeTodoToday } from "../store/todosReducer";
import { removeTodoIncoming } from "../store/incomingTodosReducer";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { removeTodoCompleted } from "../store/completedTodosReducer";
import { getTodos } from "../asyncActions/getTodosToday";
import { getTodosIncoming } from "./getIncomingTodos";
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { getCalendarTodos } from "./CalendarService/getCalendarTodos";
import { getSpentTime } from "./SpentTimeTask/getSpentTime";
import { getTodosCompleted } from "./getTodosCompleted";
import { removeTimer } from "../store/RunTimeTaskResults/RunTimeResults";

export const removeTodo = (payload) => {
    const dataLoad = {pageSize: 10, pageIndex: 1};
    return async dispatch => {
        // eslint-disable-next-line
        await request.put('/Task/Delete?Id=' + `${payload.id ? payload.id : payload}`);
        dispatch(removeTimer(payload.id ? payload.id : payload))
        dispatch(removeTodoToday(payload.id ? payload.id : payload));
        dispatch(removeTodoIncoming(payload.id ? payload.id : payload));
        dispatch(removeTodoCompleted(payload.id ? payload.id : payload));
        setTimeout(() => {
            dispatch(getTimeAllTodos());
            dispatch(getCountTime());
            dispatch(getTodosCompleted(dataLoad))
            if (payload?.dateFromCalendarPage) {
                dispatch(getCalendarTodos(payload?.dateFromCalendarPage));
            }
            dispatch(getTodosIncoming(dataLoad));
            dispatch(getTodos());
            dispatch(getSpentTime());
        }, 500);
        }
    }
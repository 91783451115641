import { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import request from '../Requests/requests';
import DonationBtn from '../UIcomponents/DonationBtn/DonationBtn';
import { ReactComponent as ModalLogo } from "../icons/svg/modalLogo.svg";
import Cookies from 'universal-cookie';

const DDMenu = ({active, setActive, avatar, updateModalState, updateTitleModal, updateDescriptionModal, updateLogoModal, updateButtonsModal}) => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    function Logout() {
        setActive(false);
        localStorage.removeItem('token');
        localStorage.removeItem('authToken');
        cookies.remove('refreshToken');
        navigate('/login', {require: true});
    }
    const dataToModal = {
        title: 'Сделайте свой вклад в развитие проекта',
        description:
        <div style={{maxWidth: '444px', display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <p>Нам важна ваша поддержка 💪 <br />Пожертвования пойдут на развитие проекта и обеды разработчиков, 
                чтобы они могли чувствовать себя еще более комфортно в своих 
                непрерывных битвах с багами. 
            </p>
            <p>Но не думайте, что наша команда только ест и шутит! Мы трудимся 
                день и ночь, чтобы создать удобный продукт, который сделает вашу 
                жизнь и работу легче.
            </p>
            <p>Даже если вы передумали пожертвовать, то наши разработчики уже 
                готовятся к 🍔 бургерным слезам.
            </p>
        </div>,
        logo: <ModalLogo />,
        buttons: <DonationBtn redirectTo='https://yoomoney.ru/to/4100118394034625' title='Поддержать проект'/>
    }
    useEffect(() => {
        request.get('/Account/User/', {
            params: {UserId: localStorage.getItem('token') }
        })
        .then(response =>{
            localStorage.setItem('firstname', response.data.name === null ? '' : response.data.name);
            localStorage.setItem('lastname', response.data.lastname === null ? '' : response.data.lastname);
            localStorage.setItem('position', response.data.position === null ? '' : response.data.position)
        })
    }, [])

    return(
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)}>
            <div className='menu-content' onClick={e => e.stopPropagation()}>
                    <div className='a-list-menu__person-data'>
                        <img className='user-avatar' src={avatar} alt=''/>
                        <div className='a-list-menu__person-info'>
                            <div className='a-list-menu__person-name'>
                                <p>{localStorage.getItem('firstName')}</p>
                                <p>{localStorage.getItem('lastName')}</p>
                            </div>
                            <div className='a-list-menu__person-post'>
                                <p>{localStorage.getItem('position')}</p>
                            </div>
                        </div>
                    </div>
                <ul className='a-list-menu'>
                    <Link to="/profile" 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-1'/>
                            Профиль
                    </Link>
                    <Link to='/statistics' 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-2'/>
                            Статистика
                    </Link>
                    <Link to='/settings' 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-3'/>
                            Настройки
                    </Link>
                    <Link to='/completed' 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-4'/>
                            Выполненные
                    </Link>
                    <Link to='/removed_todo' 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-5'/>
                            Корзина
                    </Link>
                    <Link to='/tags' 
                        className='a-menu a-item-menu' 
                        onClick={() => setActive(false)}>
                            <span className='bg_menu-6'/>
                            Метки
                    </Link>
                    {/* <li className='a-item-menu'>
                            <span className='bg_menu-7'/>
                            <span className='a-menu' href="#">Премиум</span>
                    </li> */}
                    <li className='a-item-menu' onClick={() => Logout()}>
                        <span className='bg_menu-8'/>
                        <span className='a-menu' href="#">Выйти</span>
                    </li>
                </ul>
                <div style={{display: 'flex', justifyContent:'center'}} onClick={() => setActive(false)}>
                    <DonationBtn 
                        updateModalState={updateModalState} 
                        updateTitleModal={updateTitleModal}
                        updateDescriptionModal={updateDescriptionModal}
                        updateLogoModal={updateLogoModal}
                        updateButtonsModal={updateButtonsModal}
                        dataToModal={dataToModal}       
                        title='Поддержать проект'/>
                </div>
            </div>
        </div>
    )
}

export default DDMenu;
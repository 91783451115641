import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { addWorkTime } from "../asyncActions/WorkTimeActions/addWorkTime";
import request from "../Requests/requests";
import { getSpentTime } from "../asyncActions/SpentTimeTask/getSpentTime";
import { CircularProgressbar } from 'react-circular-progressbar';
import NotificationWarning from "./NotificationWarning";
import {ReactComponent as ArrowMobile} from '../../src/icons/svg/dashboard_ic/arrow_mobile-input.svg';
import 'react-circular-progressbar/dist/styles.css';
import './dashboardStyles.css';


export default function Dashboard() {
    const dispatch = useDispatch();
    const time = useSelector(state => state.time.time);
    const countTask = useSelector(state => state.countTask.countTask);
    const spentTime = useSelector(state => state.spentTime.spentTime);
    const countPercentTask = useSelector(state => state.countPercentTask.countPercentTask);
    const [workTime, setWorkTime] = useState('00:00');
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        dispatch(getTimeAllTodos());
        dispatch(getCountTime());
        getWorkTime();
        dispatch(getSpentTime());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    async function getWorkTime() {
        await request.get('/Time/WorkTime/', { params: { userId: localStorage.getItem('token') } })
            .then(response => response.data)
            .then(data => setWorkTime(data.time))
    }

    function pushWorkTime() {
        const obj = {
            workTime: workTime
        }
        dispatch(addWorkTime(obj));
    }

    useEffect(() => {
        let date = new Date();
        let momentTime = date.getHours() * 60 + date.getMinutes();
        if (workTime !== undefined) {
            let workHours = Number(workTime.slice(0, 2));
            let workMinutes = Number(workTime.slice(3, 6));
            let endWorkIn = workHours * 60 + workMinutes;
            if (time.length !== 0) {                
                let timeDifference = endWorkIn - momentTime;
                let timeToTask = time[0].hours * 60 + time[0].minutes;
                if(timeToTask === 0){
                    setWarning(false); //false
                    return;
                }
                if (timeDifference < timeToTask) {
                    setWarning(true); // true
                } else if (timeDifference > timeToTask){
                    setWarning(false); //false
                }
            }
            
        }
    }, [workTime, time]);

    return (
        <div className="dashboard">
            {
                time.length && countTask.length && spentTime.length === 0 ? 
                    <div className="dashborad__loading-data">
                        <div className="loader" />
                    </div> 
                    :
                    <div className="dashboard__container">
                        <div className="dashboard__time">
                            <p className="dashboard__time__description">Время на задачи</p>
                            {time.map((time, index) => 
                                <p className="dashboard__status-bar__time-to-tasks" key={index}>
                                    {time.hours}
                                    <p style={{marginLeft: '4px', marginRight: '4px'}}>ч</p> 
                                    {time.minutes}
                                    <p style={{marginLeft: '4px'}}>мин</p></p>)}
                        </div>
                        {warning ?
                            <div className="warning">
                                <NotificationWarning />
                            </div>
                            :
                            null
                        }
                        <div className="dashboard__status-bar">
                            <ul className="dashboard__status-bar__list">
                                <li className="dashboard__status-bar__item spent_item">
                                    <p className="dashboard__status-bar__description">Выполнено</p>
                                    {spentTime.map((spentTime, index) => 
                                        <p className="dashboard__status-bar__data" key={index}>
                                            {spentTime.hours}
                                            <p className="dashboard__status-bar__data__letter">ч</p>
                                            {spentTime.minutes}
                                            <p className="dashboard__status-bar__data__letter">мин</p>
                                        </p>)}
                                    
                                </li>
                                <li className="dashboard__status-bar__item task_item">
                                    <p className="dashboard__status-bar__description">Задачи</p>
                                    {countTask.map((countTask, index) => 
                                        <p className="dashboard__status-bar__data dashboard__task" 
                                            key={index}>
                                            {countTask.completedTasks}/{countTask.totalTasks}
                                        </p>)}
                                </li>
                                <li className="dashboard__status-bar__item chart_item">
                                    <div className="dashboard__progress-bar">
                                        <CircularProgressbar value={countPercentTask} 
                                            strokeWidth={3} 
                                            text={`${Math.floor(countPercentTask * 10 / 10)}%`} 
                                            styles={{
                                                path: {
                                                    stroke: `#546FFF`,
                                                },
                                                trail: {
                                                    stroke: '#546FFF',
                                                    opacity: 0.1,
                                                },
                                                text: {
                                                    fill: '#FFFFFF',
                                                    fontSize: '22px',
                                                    lineHeight: '150%',
                                                },
                                        }} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="end_day_item">
                            <p className="dashboard__status-bar__description end_day_description">Конец рабочего дня
                                <span className="before_p">
                                    до
                                    <input type="time" 
                                        className="dashborad__input-work-time" 
                                        value={workTime} 
                                        onChange={(e) => setWorkTime(e.target.value)} 
                                        onBlur={() => pushWorkTime()}> 
                                    </input>
                                    <span className="dashborad__input-work-time__mobile-container">
                                        <input type="time"
                                            className="dashborad__input-work-time__mobile" 
                                            value={workTime} 
                                            onChange={(e) => setWorkTime(e.target.value)} 
                                            onBlur={() => pushWorkTime()}> 
                                        </input>
                                        <ArrowMobile />
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
            }
        </div>
    )
}

export default function NotificationWarning(){

    setTimeout(() => {
        const notification = document.querySelector('.notification_warning');
        if(notification){
            notification.classList.remove("visib");
            notification.classList.add("no_visib");
        }
    }, 3000)

    return(
        <div className="notification_warning">
            <div className="visib diamond_in_notifiction"></div>
            <p className="notification_warning_title">Время на задачи превышено!</p>
            <p className="notification_warning_description">Скорректируйте время. Вы не успеете выполнить все задачи.</p>
        </div>
    )
}
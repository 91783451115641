
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector} from 'react-redux/es/exports';
import { useLocation } from "react-router-dom";
import { getCompletedTodosOnRange } from "../asyncActions/StatisticsActions/getCompletedTodosOnRange";
import { getElapsedTimeToTodos } from "../asyncActions/StatisticsActions/getElapsedTimeToTodos";
import { getForecastAccurasy } from "../asyncActions/StatisticsActions/getForecastAccurasy";
import { useOutsideClick } from "../customHooks/useOutsideClick";
import { getTopForecast } from "../asyncActions/StatisticsActions/getTopForecast";
import { getTopTags } from "../asyncActions/StatisticsActions/getTopTags";
import TaskCompleted from "./FirstGridOfStats/TasksCompletedInfo";
import TaskForecast from "./FirstGridOfStats/TasksForecastInfo";
import BestForecast from "./SecondGridOfStats/ForecastInfo/BestForecastInfo";
import BadForecast from "./SecondGridOfStats/ForecastInfo/BadForecastInfo";
import SpentTime from "./FirstGridOfStats/SpentTimeInfo";
import TopTags from "./SecondGridOfStats/TopTagsInfo/TopTagsInfo";
import { ReactComponent as TaskSelectArrow } from "../icons/svg/TaskStats/stats__arrow.svg"
import "./index.css";
import TaskTableSection from "./TaskTableSection/TaskTableSection.tsx";
import DDSelectDate from "../DropDownMenu/SelectDateCalendar/DDSelectDate";
import CustomInputSelect from "../UIcomponents/CustomInputSelect/CustomInputSelect";
import ConverterUTC from "../ConverterUTC/ConverterUTC";

const TaskStats = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const settingsUser = useSelector(state => state.settings.settings)
    // Получение сегодняшней даты, инициализация статистики происходит сначала сегодняшнего дня 
    const [date, setDate] = useState(new Date());
    const dateNormalizeStart = location.state ? location.state.dateRange.start : date?.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1');
    const dateNormalizeEnd = location.state ? location.state.dateRange.end : date?.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1');
    // Получение начала текущего месяца
    const getStartMonth = new Date(date?.getFullYear(), date?.getMonth()).toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1');
    // Массив периода
    const [currentRange, setCurrentRange] = useState({start: dateNormalizeStart, end: dateNormalizeEnd})
    // Тип информации по тегам
    const [defaultTagsType, setDefaultTagsType] = useState(1);
    const [isActiveDateRange, setIsActiveDateRange] = useState(false);
    const [typeOfSelectedRange, setTypeOfSelectedRange] = useState('за сегодня');
    // Получение данных из хранилища
    const completedTodosOnRange = useSelector(state => state.completedTodosOnRange.completedTodosOnRange)
    const elapsedTimeToTodos = useSelector(state => state.elapsedTimeToTodos.elapsedTimeToTodos)
    const countPercentTask = useSelector(state => state.countPercentTask.countPercentTask)
    const topForecast = useSelector(state => state.topForecast.topForecast)
    const topTags = useSelector(state => state.topTags.topTags)
    // Стили для календаря
    const stylesCalendar = {
        width: '290px'
    };
    const stylesCalendarNavi = {
        width: '290px',
        justifyContent: 'space-between',
        margin: '0px 0px 12px 0px'
    };
    const stylesCalendarTable = {
        width: '279px',
        margin: '12px 0px 0px 0px'
    }
    const stylesCalendarMonthDate = {
        width: '39px',
        height: '39px'
    }

    const calendarPosition = 
        window.screen.width < 577 ? {
        top: '15px',
        right: '50%',
        left: '50%',
        transform: 'translate(-45%, 0%)'
    } : {top: '15px'}
    
    useEffect(() => {
        setDate(ConverterUTC({method: "object", settingsUser: settingsUser}))
    }, [settingsUser])

    // Хук для закрытия календаря
    const refCloseDateRange = useRef();
    useOutsideClick(refCloseDateRange, () => setIsActiveDateRange(false), isActiveDateRange);

    // Обновление типа запроса по тегам
    const updateDataDefaultTagsType = (payload) => {
        let sortedRange = [currentRange?.start, currentRange?.end].sort()
        setDefaultTagsType(payload)
        dispatch(getTopTags({DateStart:sortedRange[0], DateEnd:sortedRange[1], StatisticTagsType:payload}));
    }

    // Обновление периода и вызов функций для получения статистики по периоду
    const updateSelectedDataRange = (payload) => {
        setCurrentRange({start:payload.rangeData[0], end:payload.rangeData[1]})
        if (((payload.rangeData[0] !== dateNormalizeStart) && (payload.rangeData[1] !== dateNormalizeEnd)) ||
        ((payload.rangeData[0] !== getStartOfWeek()) && (payload.rangeData[1] !== dateNormalizeStart)) || 
        ((payload.rangeData[0] !== getStartMonth) && (payload.rangeData[1] !== dateNormalizeStart))) {
            setTypeOfSelectedRange('за период')
        }
        updateData(payload.rangeData[0],payload.rangeData[1])
    }

    // Функция для получения конца текущей недели
    // function getEndOfWeek() {  
    //     date.setDate(date.getDate() - date.getDay() + (date.getDay() ? 7 : 0));
    //     date.setHours(23, 59 - date.getTimezoneOffset(), 59, 0);
    //     console.log(date.toLocaleDateString())
    //     console.log(date.getDate());
    // }

    // Функция для получения начала текущей недели
    const getStartOfWeek = () => {
        const startWeek = new Date()  
        startWeek.setDate(date?.getDate() - date?.getDay() + (date?.getDay() === 0 ? -6 : 1));
        return (startWeek.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1'));
    }

    // Обновление данных по статистике
    const updateData = (DateStart, DateEnd) => {
        let sortedRange = [DateStart, DateEnd].sort()
        dispatch(getTopTags({DateStart:sortedRange[0], DateEnd:sortedRange[1], StatisticTagsType:defaultTagsType}));
        dispatch(getCompletedTodosOnRange({DateStart:sortedRange[0], DateEnd:sortedRange[1]}));
        dispatch(getTopForecast({DateStart:sortedRange[0], DateEnd:sortedRange[1]}));
        dispatch(getElapsedTimeToTodos({DateStart: sortedRange[0], DateEnd: sortedRange[1]}));
        dispatch(getForecastAccurasy({DateStart:sortedRange[0], DateEnd:sortedRange[1]}));
    }
    
    // Функция для обновления статистики в зависимости от типа запроса (сегодня, неделя, месяц)
    const onChangePeriodOfStatistic = (value) => {
        switch (value) {
            case 'onToday': 
                return (
                    updateData(dateNormalizeStart, dateNormalizeEnd) 
                    & setTypeOfSelectedRange('за сегодня')
                    & setCurrentRange({start: dateNormalizeStart, end: dateNormalizeEnd})
                    & setIsActiveDateRange(false)
                )
            case 'onWeek':
                return (
                    updateData(getStartOfWeek(), dateNormalizeStart) 
                    & setTypeOfSelectedRange('за неделю')
                    & setCurrentRange({start: getStartOfWeek(), end: dateNormalizeStart}) 
                    & setIsActiveDateRange(false)
                )
            case 'onMonth':
                return (
                    updateData(getStartMonth, dateNormalizeEnd)
                    & setTypeOfSelectedRange('за месяц')
                    & setCurrentRange({start: getStartMonth, end: dateNormalizeEnd})  
                    & setIsActiveDateRange(false)
                )
            default:
                break;
        }
    }

    useEffect(() => {
        props.getUrlData(location.pathname)
    })
    
    useEffect(() => {
        dispatch(getElapsedTimeToTodos({DateStart: dateNormalizeStart, DateEnd: dateNormalizeEnd}));
        dispatch(getCompletedTodosOnRange({DateStart:dateNormalizeStart, DateEnd:dateNormalizeEnd}));
        dispatch(getForecastAccurasy({DateStart:dateNormalizeStart, DateEnd:dateNormalizeEnd}));
        dispatch(getTopForecast({DateStart:dateNormalizeStart, DateEnd:dateNormalizeEnd}));
        dispatch(getTopTags({DateStart:dateNormalizeStart, DateEnd:dateNormalizeEnd, StatisticTagsType:defaultTagsType}));
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getCompletedTodosOnRange, getForecastAccurasy, getTopForecast, getElapsedTimeToTodos, getTopTags])

    return (
        <div className="stats-main-container">
            <div  className="stats-main-container__header">
                <h1 className="stats-header">Статистика</h1>
                <div ref={refCloseDateRange}>
                    <CustomInputSelect title={typeOfSelectedRange} 
                                        logo={<TaskSelectArrow />} 
                                        onClickFunc={() => setIsActiveDateRange(!isActiveDateRange)} />
                {/* <span  className= "input-select stats-period-select"
                        onClick={() => setIsActiveDateRange(!isActiveDateRange)}>
                            <p>{typeOfSelectedRange}</p>
                            <span><TaskSelectArrow /></span>
                    </span> */}
                    
                            <DDSelectDate
                                statisticsButton
                                active={isActiveDateRange}
                                typeOfCalendar={3}
                                currentRange={currentRange}
                                setCurrentRange={setCurrentRange}
                                updateSelectedDataRange={updateSelectedDataRange}
                                onChangePeriodOfStatistic={onChangePeriodOfStatistic} 
                                setActive={setIsActiveDateRange} 
                                modalShow={false}
                                calendarPosition={calendarPosition}
                                stylesCalendar={stylesCalendar}
                                stylesCalendarMonthDate={stylesCalendarMonthDate}
                                stylesCalendarNavi={stylesCalendarNavi}
                                stylesCalendarTable={stylesCalendarTable}
                                />
                </div>
            </div> 
            <div className="wrapper-stats-tablet">
                {
                    ((window.screen.width <= 1024) && (window.screen.width >= 768)) ?
                        <>
                            <ul className="first-line__list-mobile">
                                <li className="first-line__list-item">
                                    <TaskCompleted completedTodosOnRange={completedTodosOnRange}/>
                                </li>
                                <li className="first-line__list-item">
                                    <TaskForecast countPercentTask={countPercentTask}/>
                                </li>
                            </ul>
                            <ul className="second-line__list-mobile">
                                <li className="first-line__list-item">
                                    <SpentTime elapsedTimeToTodos={elapsedTimeToTodos}/>
                                </li>
                                <li className="second-line__list-item toptag-bg">
                                    <TopTags defaultTagsType={defaultTagsType} 
                                        updateDataDefaultTagsType={updateDataDefaultTagsType} 
                                        topTags={topTags}
                                        onChangeTagType={updateDataDefaultTagsType}/>
                                </li>
                            </ul>
                            <ul className="third-line__list-mobile">
                                <li className="second-line__list-item bestforec-bg">
                                    <BestForecast topForecast={topForecast}
                                        currentRange={currentRange}/>
                                </li>
                                <li className="second-line__list-item badforec-gb">
                                    <BadForecast topForecast={topForecast}
                                        currentRange={currentRange}/>
                                </li>
                            </ul>
                        </> : 
                        <>
                            <ul className="first-line__list">
                                <li className="first-line__list-item">
                                    <TaskCompleted completedTodosOnRange={completedTodosOnRange}/>
                                </li>
                                <li className="first-line__list-item">
                                    <TaskForecast countPercentTask={countPercentTask}/>
                                </li>
                                <li className="first-line__list-item">
                                    <SpentTime elapsedTimeToTodos={elapsedTimeToTodos}/>
                                </li>
                            </ul>
                            <ul className="second-line__list">
                                <li className="second-line__list-item toptag-bg">
                                    <TopTags defaultTagsType={defaultTagsType} 
                                        updateDataDefaultTagsType={updateDataDefaultTagsType} 
                                        topTags={topTags}
                                        onChangeTagType={updateDataDefaultTagsType}/>
                                </li>
                                <li className="second-line__list-item bestforec-bg">
                                    <BestForecast topForecast={topForecast}
                                        currentRange={currentRange}
                                    />
                                </li>
                                <li className="second-line__list-item badforec-gb">
                                    <BadForecast topForecast={topForecast}
                                        currentRange={currentRange}/>
                                </li>
                            </ul>
                        </>
                }
            </div>
            <div>
                <TaskTableSection dateStart={[currentRange.start, currentRange.end].sort()[0]} dateEnd={[currentRange.start, currentRange.end].sort()[1]}/>
            </div>
            
        </div>
    )
}

export default TaskStats;
import React from "react";
import TodoListCompleted from "../../Todos/TodosCompletedApp";
import SubMenu from "../../Submenu/SubmenuApp";
import './CompletedTodosPage.css'
import '../GeneralPageStyles.css'
import {ReactComponent as CompletedIcon} from '../../../src/icons/svg/completed-title-page.svg'

export default function CompletedTodosPage({setIcon}) {
    return (
        <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="page-container">
                    <div className="page-info">
                        <CompletedIcon className="page-info__completed-icon"/>
                        <h1 className="page-title">Выполненные</h1>
                    </div>
                    <div>
                        <TodoListCompleted setIcon={setIcon}></TodoListCompleted>
                    </div>
                </div>
            </div>
    )
}
interface GetDateUTCProps {
    method: 'string' | 'object',
    settingsUser: any
}

export default function ConverterUTC({method, settingsUser} : GetDateUTCProps) {
    const currentDate = new Date()

    const currentDateStringUTC = settingsUser.timeZone > 0 ?
        new Date(currentDate.setHours(currentDate.getHours() + Math.abs(settingsUser.timeZone))).toUTCString() 
            : 
        new Date(currentDate.setHours(currentDate.getHours() - Math.abs(settingsUser.timeZone))).toUTCString();

    const currentDateObjectUTC = new Date(Date.parse(currentDateStringUTC) - (settingsUser.currentTimeZone * 60) * 60000);

    switch (method) {
        case 'string':
            return currentDateStringUTC;
        case 'object':
            return currentDateObjectUTC;
        default:
            break;
    }
}
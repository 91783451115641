import React, { useState, useEffect } from "react";
import { removeTodo } from "../asyncActions/removeTodos";
import { restoreTodo } from "../asyncActions/restoreTodos";
import { completeTodo } from "../asyncActions/comleteTodos";
import { notCompleteTodo } from "../asyncActions/notCompletedTodo";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { activateTimer } from "../store/RunTimeTaskResults/RunTimeResults";
import GroupMenu from "../GroupTaskEditing/GroupMenu";
import { addRunTime } from "../asyncActions/RunTimeActions/addRunTime";
import { ReactComponent as RestoreButton } from "../icons/svg/restore-task.svg"
import { ReactComponent as RemoveButton } from "../icons/svg/remove-task.svg"
import { ReactComponent as TagHash } from "../icons/svg/TasksLiatCards/grid.svg";
import { useSwipeable } from "react-swipeable";
import SwipeToLeftFromTask from "../SwipeMenu/swipeToLeftFromTask";
import SwipeToRightFromTask from "../SwipeMenu/swipeToRightTask";
import ContextMenuTask from "../ContextMenu/contextMenuTask";
import { changeOnContextState } from "../store/contextMenuTaskReducer";
import { useLocation, useNavigate } from "react-router-dom";
import TaskStopWatch from "../TaskTimer/TaskStopWatch";
import ConverterUTC from "../ConverterUTC/ConverterUTC";

export default function TodoList({disabled, dateFromCalendarPage, updateTaskList, disableContextMenu, pageIndex, ...props}) {
    const dispatch = useDispatch();
    const [taskData, setTaskData] = useState({
        id: null,
        userId: null,
        title: null,
        description: null,
        isCompleted: false,
        priority: null,
        hours: null,
        minutes: null,
        date: null,
        time: null,
        runTime: 0,
        isOpened: false,
        tags: [
            // {
            // isActive: false,
            // tagId: null,
            // tagName: null,
            // taskId: null
            // }
        ]
    });
    const tagsArray = props.todos.tags;
    const location = useLocation();
    const navigate = useNavigate();
    const [isSwipedRight, setIsSwipedRight] = useState(false);
    const [isSwipedLeft, setIsSwipedLeft] = useState(false);
    const settingsUser = useSelector(state => state.settings.settings)
    const stopWatchesData = useSelector(state => state.stopWatchesData.stopWatchesData);
    const onContextState = useSelector(state => state.onContextState.onContextState);
    const onContextStateToTask = onContextState.find(el => el.contextTaskId === props.todos.id);
    const actionArray = stopWatchesData.find(actionArr => actionArr.taskId === props.todos.id);
    const [modalShow, setModalShow] = useState(false);
    const titleTodos = props.todos.title;
    const descriptionTodos = props.todos.description;
    const [runTime, setRunTime] = useState(props.todos.runTime)
    const priorityColorBage = ['prority-bage-1', 'prority-bage-2', 'prority-bage-3', 'prority-bage-0'];
    const monthArray = ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'];
    const date = ConverterUTC({method: 'object', settingsUser: settingsUser})
    const dateObj = new Date(props.todos.date.replace(/(\d*).(\d*).(\d*)/, '$3-$2-$1'));
    const [contextPosition, setContextPosition] = useState({x_axis: null, y_axis: null})
    const [contextData, setContextData] = useState({});
    const [scrolledY, setScrolledY] = useState(0);
    const [onTouchPressed, setOnTouchPressed] = useState({
        event: null,
        taskId: null,
        isPressed: false
    });

    useEffect(() => {
        setTaskData(props.todos)
    }, [props.todos])

    useEffect(() => {
        const handleScrolledY = () => {
            setScrolledY(window.scrollY);
        };

        window.addEventListener('scroll', handleScrolledY);

        return () => {
            window.removeEventListener('scroll', handleScrolledY);
        };
    }, [])

    const updateRunTime = (payload) => {
        setTaskData({
            ...taskData,
            runTime: payload.seconds
        })
    }

    useEffect(() => {
        setRunTime(props.todos.runTime)
    }, [props.todos.runTime])

    const updateModalShowFromContext = (payload) => {
        setModalShow(payload)
    }

    const updateIsSwipeRight = (payload) => {
        setIsSwipedRight(payload)
    }

    const updateIsSwipeLeft = (payload) => {
        setIsSwipedLeft(payload)
    }
    useEffect(() => {
        let i = 0
        let intervalOfPress;
        if (onTouchPressed.isPressed) {
            intervalOfPress = setInterval(() => {
                i = i + 100;
                if (i === 400) {
                    clearInterval(intervalOfPress)
                    handleContextMenu(onTouchPressed.event, onTouchPressed.taskId)
                }
            }, 100);
        } else if (!onTouchPressed.isPressed) {
            clearInterval(intervalOfPress)
        }
        
        return () => clearInterval(intervalOfPress)
    })
    
    const handleSwipe = useSwipeable({
        onSwipedRight: (e) => e.absX < 68 ? setIsSwipedLeft(0) & setIsSwipedRight(68) : 
        e.absX > 200 ? props.uncompleteBtn ? dispatch(notCompleteTodo(props.todos.id)) & setIsSwipedRight(0) : 
        props.restoreBtn ? dispatch(restoreTodo(props.todos.id)) & setIsSwipedRight(0) : 
        dispatch(completeTodo({id: props.todos.id, dateFromCalendarPage: dateFromCalendarPage})) & setIsSwipedRight(0) : 
        setIsSwipedLeft(0) & setIsSwipedRight(e.absX),
        
        onSwipedLeft: (e) => e.absX < 68 ? setIsSwipedLeft(68) & setIsSwipedRight(0) : 
        e.absX > 200 ? props.restoreBtn ? setIsSwipedLeft(0) : dispatch(removeTodo({id: props.todos.id, dateFromCalendarPage: dateFromCalendarPage})) & setIsSwipedLeft(0) : 
        setIsSwipedRight(0) & setIsSwipedLeft(e.absX),
        
        onSwiping: (e) =>  e.dir === 'Right' ? setIsSwipedLeft(0) & setIsSwipedRight(e.absX)  : 
            e.dir === 'Left' ? props.restoreBtn ? setIsSwipedLeft(0) : setIsSwipedRight(isSwipedRight > 0 ? isSwipedRight - e.absX : 0) & setIsSwipedLeft(e.absX) : null,

        delta: {right: isSwipedRight >= 68 ? isSwipedRight : 10, left: isSwipedLeft >= 68 ? isSwipedLeft : 10} ,
        // preventScrollOnSwipe: true,
        trackTouch : true ,
        touchEventOptions: { passive: true }
    })
    const DynamicHeight = (value) => {
        return document.getElementById(`${value}`).offsetHeight;
    } 
    const selected = event => {
        if (isSwipedRight) {
            setIsSwipedRight(false)
            event.preventDefault()
        } else if (isSwipedLeft) {
            setIsSwipedLeft(false)
            event.preventDefault()
        } else if (isSwipedRight || isSwipedLeft) {
            event.stopPropagation()
        // } else if(event.ctrlKey){
        //     TaskSelection(event.currentTarget.id);
        //     setChangeTask(!changeTask);
        //     GroupMenu(true);
        //     TagStorage(tagsArray);
        } else if (onContextState.find(el => el.contextIsOpen === true)) {
            event.preventDefault()
        } else {
            navigate (`${location.pathname === '/' ? `${location.pathname}${props.todos.id}/modal=${!modalShow}` : `${location.pathname}/${props.todos.id}/modal=${!modalShow}`}`, {state: {path: location.pathname, dateFrom: dateFromCalendarPage, fromCompleted: props.fromCompleted ? props.fromCompleted : null, fromRemoved: props.fromRemoved ? props.fromRemoved : null}})
            // if ((isTimeTracking === 'true') && (date.toLocaleDateString() >= taskData.date)) {
            //     if ((props.todos.isOpened & props.todos.isCompleted !== true & props.todos.isDelete !== true)) {
            //         if (actionArray?.isTimerActive) {
            //             return
            //         } else {
            //             // if (activeTimer?.taskId !== taskData.id) {
            //             //     const startTime = new Date().getTime()
            //             //     dispatch(addRunTime({id: activeTimer?.taskId, seconds: Math.floor((startTime - activeTimer?.startTime) / 1000) }))
            //             //     // dispatch(timerPush({taskId: activeTimer?.taskId, timerIsActive: false, startTime: startTime}))

            //             // }
            //             var startTime = new Date().getTime();
            //             dispatch(timerPush({taskId: props.todos.id, timerIsActive: true, startTime: startTime}))
            //             sendTimer(startTime)
            //         }
            //     }
            // }
            GroupMenu(false);
        }
    }
    function StartStopTimer() {
        dispatch(activateTimer({isTimerActive: actionArray.isTimerActive = false, taskId: props.todos.id, startTime: actionArray.startTime}))
        dispatch(addRunTime({id: actionArray.taskId, seconds: runTime}));
    }

    let dataSwipeRight = {
        dynamicHeight: DynamicHeight,
        update: updateIsSwipeRight,
        stopTimer: StartStopTimer,
        swipe: isSwipedRight
    }
    let dataSwipeLeft = {
        dynamicHeight: DynamicHeight,
        update: updateIsSwipeLeft,
        stopTimer: StartStopTimer,
        swipe: isSwipedLeft
    }

    const handleContextMenu = (e, taskId) => {
        e.preventDefault()
        setContextPosition({x_axis: e.pageX ? e.pageX : e.targetTouches[0].pageX, y_axis: e.pageY ? e.pageY : e.targetTouches[0].pageY})
        setContextData({taskId, pageIndex})
        dispatch(changeOnContextState({contextIsOpen: true, contextTaskId: taskId}))
    }

    const handleCloseContext = (taskId) => {
        dispatch(changeOnContextState({contextIsOpen: false, contextTaskId: taskId}))
    }

    useEffect(() => {
        if (scrolledY > 450) {
            handleCloseContext(props.todos.id)
        }
        // eslint-disable-next-line
    }, [scrolledY])

    // useEffect(() => {
    //     const newConnection = new signalR.HubConnectionBuilder()
    //         .withUrl(url)
    //         .withAutomaticReconnect()
    //         .build()
    //     setConnection(newConnection)
    // }, []);

    // useEffect(() => {
    //     if (connection) {
    //         connection.start()
    //           .then(() => {
    //             connection.on("Receive", (userId, taskId, timerStart, isActive) => {
    //                 if (userId === localStorage.getItem('token')) {
    //                     dispatch(activateTimer({isTimerActive: isActive, taskId: taskId, startTime: timerStart}));                     
    //                         // if (modalShow === true) {
    //                         //     setTimeout(() => {
    //                         //         updateTaskFunc()
    //                         //     }, 500);
    //                         // }
                        
    //                 }
    //                 });
    //           })
    //           .catch((err) => console.log('Error while establishing connection: ', err));
    //       }
    //       /* eslint-disable-next-line react-hooks/exhaustive-deps */
    // }, [connection])

    // const sendTimer = (startTime) => {
    //     connection.invoke("Send", localStorage.getItem('token'), props.todos.id, startTime, true)
    //       .catch((err) => console.error(err));
    // }

    return (
        <>   
                <li id={props.todos.id}
                    {...handleSwipe}
                    className={"todo-list__item"} 
                    onClick={selected}
                    onTouchStart={(e) => disableContextMenu ? selected : setOnTouchPressed({event: e, taskId: props.todos.id, isPressed: true})}
                    onTouchEnd={() => disableContextMenu ? selected : setOnTouchPressed({...onTouchPressed, isPressed: false})}
                    onContextMenu={(e) => disableContextMenu ? e.preventDefault() : handleContextMenu(e, props.todos.id)}
                    style={isSwipedRight || isSwipedLeft ? (isSwipedRight || isSwipedLeft) >= 200 ? {transform: 'scale(1.05)', overflow:'hidden'} : {overflow: 'hidden'} :  onTouchPressed.isPressed ? {animationName: 'scaleIn', animationDuration: '0.8s', animationFillMode: "forwards"} : onContextStateToTask.contextIsOpen ? {transform: 'scale(0.96)'} : {overflow: "visible"}}>
                        <SwipeToRightFromTask 
                            updateIsSwipeRight={updateIsSwipeRight}
                            uncompleteBtn={props.uncompleteBtn}
                            restoreBtn={props.restoreBtn} 
                            todoId={props.todos.id} 
                            data={dataSwipeRight}/>
                    <div className="wrapper-todo"
                        style={isSwipedRight ? 
                            {position: "absolute", 
                            left: `${isSwipedRight }px`} : 
                            isSwipedLeft ? 
                            { position: "absolute", 
                            right: `${isSwipedLeft}px`, 
                            backgroundColor: '#FFFFFF'} : 
                            props.modalTitle === 'Корзина' ?  {justifyContent: 'flex-start', marginLeft: '16px'} : null}>
                                <div style={{width:'8px'}}/>
                                <div className={priorityColorBage[props.todos.priority]}/>
                        
                            <div className="btn-complete-todo-container__mobile" style={props.completeBtn ? {visibility: "visible"} : {visibility: 'hidden'}}>
                                <button className="btn-complete-todo" 
                                    onClick={(e) => e.stopPropagation() & dispatch(completeTodo({id: props.todos.id, dateFromCalendarPage: dateFromCalendarPage})) & updateTaskList() & StartStopTimer()}>
                                    <div className="btn-complete-todo__hov"/> 
                                </button>
                            </div> 
                          
                        <div className="todo-list__item__container" >
                            <p className="todo-list__item__container__title">{titleTodos.replace(/(<([^>]+)>)/gi, '')}</p>
                            {
                                props.todos.description === null ?
                                null 
                                :
                                <p className="todo-list__item__container__description">{descriptionTodos.replace(/(<([^>]+)>)/gi, '')}</p>
                            }
                            <div className="todo-list__info-container">
                                <div className="todo-list__date-info">
                                    <p className="todo-list__date d-flex">
                                        {isNaN(dateObj.getDate()) ? null : dateObj.getDate()} 
                                        {monthArray[dateObj.getMonth()] === undefined ?
                                            null
                                            :
                                            <div>
                                                {monthArray[dateObj.getMonth()].slice(0, 3).toLowerCase()}
                                            </div>    
                                        }
                                    </p>
                                    <p className="todo-list__datetime mx-7">
                                        {props.todos.time}
                                    </p>
                                </div>
                                <div className="todo-list__tags" id="tags-block">
                                    <ul className="todo-list__tags-mobile-ul">
                                        {tagsArray ? (<>
                                            {tagsArray.map((tagName, index) => 
                                            <li className="todo-list__tags-mobile todo-list__tags-mobile-margin" key={index}>
                                                <TagHash />
                                                <p>{tagName.tagName}</p>
                                            </li>
                                            )}
                                        </>) : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        { props.timerDisplay ?
                            <div className="todo-list__timer" onClick={(e) => e.stopPropagation()}>
                                    <TaskStopWatch updateRunTime={updateRunTime}
                                        timeTracking={settingsUser.isTimeTracking}
                                        dateCurrent={date.toLocaleDateString()}
                                        id={props.todos.id} 
                                        runTime={runTime}
                                        task={taskData}
                                        modalShow={modalShow}
                                        isOpened={props.todos.isOpened} 
                                        hours={props.todos.hours}
                                        minutes={props.todos.minutes}
                                        fromTodo={props.fromTodo}
                                        completedTodoView={props.fromCompleted ? props.fromCompleted : null}
                                        disabled={props.disabled ? props.disabled : props.fromIncoming ? false : props.fromCompleted || props.fromRemoved ? true : date.toLocaleDateString() < taskData.date ? true : null}
                                        TodoListView/>
                            </div>
                            :
                            null
                        }
                        {props.uncompleteBtn ? 
                            <div className="restore_or_remove_todos" onClick={(e) => e.stopPropagation()}>
                                <RestoreButton onClick={() => dispatch(notCompleteTodo(props.todos.id)) & updateTaskList()}
                                        title='Восстановить задачу'
                                        className="brn-restore-task" />
                                <RemoveButton onClick={() => dispatch(removeTodo(props.todos.id)) & updateTaskList()}
                                    title='Удалить задачу'
                                    className="btn-remove-task"/>
                            </div> 
                            :
                            null
                        }
                        {props.restoreBtn ?
                            <div className="restore_or_remove_todos" onClick={(e) => e.stopPropagation()}>
                                    <RestoreButton onClick={() => dispatch(restoreTodo(props.todos.id)) & updateTaskList()}
                                        title='Восстановить задачу'
                                        className="brn-restore-task" />
                            </div>                            
                            :
                            null
                        }
                    </div>
                    <SwipeToLeftFromTask updateIsSwipeLeft={updateIsSwipeLeft} todoId={props.todos.id} data={dataSwipeLeft}/>
                </li>
            
            
                {
                    onContextStateToTask.contextIsOpen ? 
                        <ContextMenuTask updateModalShowFromContext={updateModalShowFromContext} 
                                    contextData={contextData}
                                    task = {props.todos}
                                    scrolledY={scrolledY}
                                    dateFromCalendarPage={dateFromCalendarPage}
                                    dyblicateTaskToday={props.dyblicateTaskToday ? props.dyblicateTaskToday : null}
                                    fromRemoved = {props.fromRemoved ? props.fromRemoved : null}
                                    fromToday={props.fromToday ? props.fromToday : null}
                                    onContextStateToTask={onContextStateToTask}
                                    closeContext = {handleCloseContext}
                                    contextTaskpriority={props.todos.priority}
                                    contextPosition={contextPosition}/> : null
                }
        </>
    )
}
